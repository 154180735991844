import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import { fetchData } from "../../helpers/externapi";
import { MultiSelect } from "react-multi-select-component";
import CircularProgress from '@mui/material/CircularProgress';
import 'jspdf-autotable';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { downloadCSVData, downloadExcelData } from '../../Commoncomponents/CommonComponents';
import CommonTables from '../../Commoncomponents/CommonTables';
import Flatpickr from 'react-flatpickr';
import { constructCompleteAddress } from '../../Commoncomponents/CommonComponents';

export default function List(props) {
    const [loading, setLoading] = React.useState(false);
    const [tableloading, setTableLoading] = React.useState(false);
    const [selectedStates, setSelectedStates] = React.useState([]);
    const [statesMultiSelect, setStatesMultiSelect] = React.useState();
    const [selectedDistricts, setSelectedDistricts] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);
    const [filterCriteria, setFilterCriteria] = useState([]);
    const [selectedNames, setSelectedNames] = useState([]);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [selectedMandals, setSelectedMandals] = useState([]);
    const [selectedVillages, setSelectedVillages] = useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [selectedDates, setSelectedDates] = useState([]);
    const [healthcampData, setHealthcampData] = React.useState([]);
    const [isDisableApply, setIsDisableApply] = useState(true);

    const { Id } = useParams();

    const tomorrow = new Date()
    const accordionRef = useRef(null);
    tomorrow.setDate(tomorrow.getDate() + 1)

    const tableHeads = ["FULL NAME", "CONTACT", "ADDRESS", "EVENTS", "DISEASE", "Event Date"];

    const tableElements = healthcampData.length > 0 ?
        healthcampData.map(data => ([
            <Link
                to={`/customers/details/${data.MemberId}`}
                className="text-start-important"
                style={{
                    whiteSpace: 'normal',
                    textAlign: 'start',
                    display: 'block',
                }}
            >
                {data.Name}
            </Link>,
            data.MobileNumber || data.Email ? (
                <>
                    <div>
                        {data.MobileNumber ? (
                            <a href={"tel:" + data.MobileNumber}>
                                <i className='bx bx-phone-call'></i>
                                {data.MobileNumber}
                            </a>
                        ) : "Mobile Number dosen't exist"}
                    </div>
                    <div>
                        {data.Email ? (
                            <a href={"mailto:" + data.Email} className="d-flex align-items-center" style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}>
                                <i className="fas fa-envelope"></i>
                                {data.Email}
                            </a>
                        ) : "Email id doesn't exist"}
                    </div>
                </>
            ) : "Mobile Number and Email Id doesn't exist",
            data.completeAddress,
            data.EventName,
            data.MemberDiseases,
            data.EventDate ? moment(data.EventDate).format('DD-MMM-YYYY') : ''
        ])) : [];

    useEffect(() => {
        setLoading(props.loading);
        setLoading(props.error);
    }, []);

    const getDistributorCountData = async () => {
        setLoading(true);
        try {
            const distributorCountData = await fetchData(`CommonRowCount/GetTableRowCount`, { tableName: "MemberVitalsDetails" });
            const totalCount = distributorCountData[0]?.CountOfRecords || 0;
            setTotalCount(totalCount);
        } catch (error) {
            console.error("Error fetching hospital count data:", error);

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getDistributorCountData();
    }, []);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        getDistributorData();
    }, [filterCriteria, currentPage, perPage]);

    const getDistributorData = async () => {
        setLoading(true);
        try {
            const skip = (currentPage - 1) * perPage;
            const take = perPage;

            let distributorData;
            if (filterCriteria.length > 0) {
                distributorData = await fetchData("Vitals/GetMemberVitalsDetailsDataByFilter", {
                    skip,
                    take,
                    filter: filterCriteria
                });
            } else {
                distributorData = await fetchData("Vitals/GetMemberVitalsDetails/all", { skip, take });
            }

            const dataToDisplay = distributorData.map(distributor => ({
                ...distributor,
                completeAddress: constructCompleteAddress(distributor),
                MobileNumber: distributor.MobileNumber
            }));

            setHealthcampData(dataToDisplay);
        } catch (error) {
            console.error("Error fetching distributor data:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleExcelDownload = async () => {
        setLoading(true)
        await downloadExcelData('healthcamplist', totalCount, perPage, currentPage, fetchData, filterCriteria, setLoading);
    };

    const handleCSVDownload = async () => {
        setLoading(true)
        await downloadCSVData('healthcamplist', totalCount, perPage, currentPage, fetchData, filterCriteria, setLoading);
    };

    useEffect(() => {
        const getStates = async () => {
            setLoading(true);
            const statesData = await fetchData("Event/all", { "skip": 0, "take": 0 });
            const statesArray = statesData.map(item => ({ label: item.EventName, value: item.EventId }));
            setStatesMultiSelect(statesArray);
            setLoading(false);
        }
        getStates();
    }, []);

    function formatDateRange(startDate, endDate) {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };

        const start = startDate.toLocaleDateString('en-GB', options).replace(/ /g, '-');
        const end = endDate.toLocaleDateString('en-GB', options).replace(/ /g, '-');

        return `${start} to ${end}`;
    };

    const applyFilter = async () => {
        setLoading(true);

        const selectedStateIds = selectedStates.map(state => state.value);
        const fromDate = selectedDates[0] ? moment(selectedDates[0]).format('YYYY-MM-DD') : "";
        const toDate = selectedDates[1] ? moment(selectedDates[1]).format('YYYY-MM-DD') : "";

        const filterCriteria = [];
        if (selectedStateIds.length > 0) {
            filterCriteria.push({
                key: "EventId",
                value: selectedStateIds.join(","),
                operator: "IN"
            });
        }
        if (fromDate && toDate) {
            filterCriteria.push({
                key: "EventDate",
                value: `${fromDate},${toDate}`,
                operator: "BETWEEN"
            });
        }

        try {

            const distributorCountData = await fetchData(`CommonRowCount/GetTableRowCount`, { tableName: "MemberVitalsDetails", filter: filterCriteria });
            const totalCount = distributorCountData[0]?.CountOfRecords || 0;
            setTotalCount(totalCount);

            const filterData = await fetchData("Vitals/GetMemberVitalsDetailsDataByFilter", {
                skip: 0,
                take: perPage,
                filter: filterCriteria
            });

            //const filterCount = await fetchData("Vitals/GetMemberVitalsDetailsDataByFilter", {
            //    countOnly: true,
            //    filter: filterCriteria
            //});
            //const totalCount = filterCount.length;
            //setTotalCount(totalCount);
            setPerPage(perPage);
            setCurrentPage(1);
            setHealthcampData(filterData.map(member => ({
                ...member,
                MobileNumber: member.MobileNumber
            })));
            setFilterCriteria(filterCriteria);
        } catch (error) {
            console.error("Error applying filter:", error);
        } finally {
            setLoading(false);
        }
    };

    const clearFilters = async () => {
        setSelectedStates([]);
        setSelectedDates([]);
        getDistributorCountData();
        try {
            await getDistributorData();
            setFilterCriteria([]);
        } catch (error) {
            console.error("Error while clearing filters:", error);
        }
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    useEffect(() => {
        if (selectedStates.length === 0 && selectedDates.length === 0) {
            setIsDisableApply(true);
        } else {
            setIsDisableApply(false);
        }

    }, [selectedStates, selectedDates]);

    const skeletonloading = () => (
        <>
            <style>{shimmerStyle}</style>
            <div className="shimmer-container shimmer">
                <h6 className="shimmer-text "></h6>
            </div>
            <table className="shimmer-container shimmer">
                <tbody>
                    {Array.from({ length: 6 }).map((_, rowIndex) => (
                        <tr key={rowIndex} className="shimmer-row">
                            {Array.from({ length: 6 }).map((_, colIndex) => (
                                <td key={colIndex} className="shimmer-cell">
                                    <h6 className="shimmer-text2 " ></h6>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

        </>
    );

    return (
        <>
            {loading && skeletonloading()}
            {!loading && (
                <>
                    <div className="card mb-2">
                        <div className="p-3">
                            <div className="select2-primary mx-2" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {(selectedStates.length > 0 || selectedDates.length > 0) && (
                                    <>
                                        <strong style={{ marginRight: '5px' }}>Filter Criteria - </strong>

                                        {selectedStates.length > 0 && (
                                            <div style={{ marginRight: '10px', marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                                                <strong style={{ marginRight: '5px' }}>Event : </strong>
                                                {selectedStates.map((state, index) => (
                                                    <span key={state.value} className="selected-option-button">
                                                        {state.label}
                                                        {index !== selectedStates.length - 1 && ', '}
                                                    </span>
                                                ))}
                                            </div>
                                        )}

                                        {selectedDates.length > 0 && (
                                            <div style={{ marginRight: '10px', marginBottom: '0px', display: 'flex', alignItems: 'center' }}>
                                                <strong style={{ marginRight: '5px' }}>Selected Dates: </strong>
                                                <span className="selected-option-button">
                                                    {formatDateRange(selectedDates[0], selectedDates[selectedDates.length - 1])}
                                                </span>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            <div className="d-flex flex-row flex-wrap align-items-center">
                                <div className="me-2 me-md-3 mb-2">
                                    <label htmlFor="select2Success" className="form-label my-0">Events</label>
                                    <div className="select2-primary" style={{minWidth: '200px'}}>
                                        {statesMultiSelect && (
                                            <MultiSelect
                                                options={statesMultiSelect}
                                                value={selectedStates}
                                                onChange={setSelectedStates}
                                            />
                                        )}
                                    </div>
                                </div>

                                <div className="me-2 me-md-3 mb-2">
                                    <label htmlFor="fromDate">Select Event Dates:</label>
                                    <Flatpickr
                                        value={selectedDates}
                                        placeholder="YYYY-MM-DD to YYYY-MM-DD"
                                        onChange={setSelectedDates}
                                        options={{ mode: "range", dateFormat: "Y-m-d" }}
                                        className="form-control"
                                    />
                                </div>

                                <div className="d-flex flex-row align-items-center text-center">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        data-bs-dismiss="modal"
                                        onClick={clearFilters}
                                    >
                                        Clear
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-dismiss="modal"
                                        onClick={applyFilter}
                                        disabled={isDisableApply}
                                    >
                                        Apply
                                    </button>
                                </div>

                            </div>


                            {/* <div className="modal-footer">
                                
                            </div> */}
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 order-0 order-md-1">
                            <div className="card mb-4" style={{ opacity: loading ? 0.5 : 1 }}>
                                <div className="p-1">
                                    <div className="row mb-2">
                                        <div className="col-md-12 d-flex flex-row-reverse content-between align-items-center gap-2">
                                            <button className="btn btn-secondary create-new btn btn-sm btn-primary" onClick={handleExcelDownload}>
                                                <span><i className="bx bx-export me-sm-1"></i>
                                                    <span className="d-none d-sm-inline-block"> Excel</span>
                                                </span>
                                            </button>

                                            <button className="btn btn-secondary create-new btn btn-sm btn-success" onClick={handleCSVDownload}>
                                                <span><i className="bx bx-export me-sm-1"></i>
                                                    <span className="d-none d-sm-inline-block"> CSV</span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    {(loading || tableloading) && (
                                        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.7)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 1000 }}>
                                            <CircularProgress />
                                        </div>
                                    )}

                                    {!loading && !tableloading && healthcampData.length === 0 && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            There are no records to display.
                                        </div>
                                    )}

                                    {!loading && !tableloading && healthcampData.length > 0 && (
                                        <CommonTables
                                            tableHeads={tableHeads}
                                            tableData={tableElements}
                                            perPage={perPage}
                                            currentPage={currentPage}
                                            perPageChange={handlePerPageChange}
                                            pageChange={handlePageChange}
                                            totalCount={totalCount}
                                        />
                                    )}

                                    <Snackbar
                                        open={snackbarOpen}
                                        autoHideDuration={3000}
                                        onClose={handleSnackbarClose}
                                    >
                                        <Alert onClose={handleSnackbarClose} severity="success">
                                            {snackbarMessage}
                                        </Alert>
                                    </Snackbar>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );

};

const shimmerStyle = `
     @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  .shimmer {
    animation-duration: 1.0s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background:	#F7F7F7;
    background: linear-gradient(to right, #f0f0f0 8%, #fafafa 18%, #f0f0f0 33%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
  }

  .shimmer-container {
    background-color: 	#F7F7F7;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    margin: 15px;
  }

  .shimmer-text2 {
    background-color: #C8C8C8;
    border-radius: 4px;
    height: 15px;
    width: 55%;
    margin: 15px 0 0 15px;
     position:relative;
     left:10%;
     bottom:10%;
  }
  .shimmer-text {
    background-color: #C8C8C8;
    border-radius: 4px;
    height: 15px;
    width: 15%;
    margin: 15px 0 0 15px;
     
  }
 .shimmer-row {
    display: flex;
  }

  .shimmer-cell {
    flex: 1;
    padding: 10px;
    height: 50px;
    background-color: #F7F7F7;
    border-radius: 4px;
    margin: 5px;
  }
  
  `;

const styles = {
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        padding: '12px 15px',
        border: '1px solid #ddd',
        textAlign: 'left',
        backgroundColor: '#f2f2f2',
        color: '#333',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
    },
    td: {
        padding: '12px 15px',
        border: '1px solid #ddd',
        textAlign: 'left',
        fontSize: '14px',
        whiteSpace: 'normal',
        maxWidth: '200px',
    },
    headerRow: {
        backgroundColor: '#f9f9f9',
    },
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
    },
    paginationSelect: {
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid',
        marginRight: '10px',
        borderColor: 'blue',
    },
};