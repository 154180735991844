import React, { useEffect, useState } from 'react';
import "cleave.js/dist/addons/cleave-phone.in";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchData, fetchUpdateData, uploadImage, fetchDeleteData, fetchAllData } from "../../helpers/externapi";
import ReactQuill from 'react-quill';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { MultiSelect } from "react-multi-select-component";

export default function Registration() {
    const location = useLocation();
    const profileFromLocation = location.state ? location.state.profile : null;
    const otherChargesFromLocation = location.state ? location.state.otherCharges : null;
    const [isAddForm, setIsAddForm] = useState(false);
    const navigate = useNavigate();
    const [formError, setFormError] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [products, setProducts] = useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const initialFormData = {
        productFormData: {
            ProductName: "", Image: "", LongDescription: "", ShortDescription: "", MaximumAdult: "", MaximumChild: "",
            ServiceProvider: "", MinimumAge: "", MaximumAge: "", ChildrenAge: "", WelcomeEmail: "", KeyFeatures: "",
            IsDefault: false, IsFree: false, IsActive: false, IsNomineeRequired: false, EndorseEmail: "",
            ProductCategoryId: "", SumAssured: "", IsCombo: false, IsCorporate: false, SaleAmount: 0, MaximumMembers: ""
        }, insurancePremiumFormData: {
            MinimumAge: "", MaximumAge: "", BasePremium: "", GST: "", TotalAmount: ""
        }, otherChargesFormData: {
            LabelName: "", Value: "", IsFixed: "", CreatedBy: ""
        }
    }
    const [formData, setFormData] = useState([initialFormData]);
    const [rows, setRows] = useState([{ MinimumAge: "", MaximumAge: "", BasePremium: "", GST: "", TotalAmount: "" }]);
    const [offersRows, setOffersRows] = useState([]);
    const [additionalRows, setAdditionalRows] = useState([{ LabelName: "", Value: "", IsFixed: true, CreatedBy: "" }]);
    const [allProducts, setAllProducts] = useState();
    const [prodNames, setProdNames] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [memberTypes, setMemberTypes] = useState([]);
    const [offersFormError, setOffersFormError] = useState('');

    let UserId = localStorage.getItem("UserId");

    const addRow = (event) => {
        event.preventDefault();
        if (rows.length < 20) {
            const newRow = {
                "SLNo": rows.length + 1,
                "MinimumAge": "",
                "MaximumAge": "",
                "BasePremium": "",
                "GST": "",
                "TotalAmount": ""
            };
            setRows([...rows, newRow]);
        } else {
            alert("Maximum limit of 20 rows reached.");
        }
    };

    const addOffersRow = (event) => {
        event.preventDefault();
        if (offersRows.length < 5) {
            const newRow = {
                "SLNo": rows.length + 1,
                "MemberTypeId": "",
                "OfferAmount": "",
                "OfferPercentage": "",
                "TotalAmount": rows[0].TotalAmount
            };
            setOffersRows([...offersRows, newRow]);
        } else {
            alert("Maximum limit of 5 rows reached.");
        }
    };

    const addAdditionalRow = (event) => {
        event.preventDefault();
        if (additionalRows.length < 20) {
            const newRow = {
                "SLNo": additionalRows.length + 1,
                "Inflated Rate": "",
                "Tax %": "",
                "Other Charges": "",
                "Total Amount": "",
            };
            setAdditionalRows([...additionalRows, newRow]);
        } else {
            alert("Maximum limit of 20 rows reached.");
        }
    };

    const deleteRow = async (index) => {
        const rowToDelete = rows[index];
        if (index > 0) {
            if (rowToDelete.InsurancePremiumId) {
                try {
                    const deleteResponse = await fetchDeleteData(`InsurancePremium/delete/${rowToDelete.InsurancePremiumId}`);

                    if (!deleteResponse) {
                        throw new Error(`Failed to delete insurance premium with ID: ${rowToDelete.InsurancePremiumId}`);
                    }

                    const updatedRows = rows.filter((row, i) => i !== index);
                    setRows(updatedRows.map((row, i) => ({ ...row, SLNo: i + 1 })));

                } catch (error) {
                    console.error('Error deleting row:', error.message);
                    alert('Failed to delete the row, please try again.');
                }
            } else {
                const updatedRows = rows.filter((row, i) => i !== index);
                setRows(updatedRows.map((row, i) => ({ ...row, SLNo: i + 1 })));
            }
        }
    };

    const deleteOffersRow = async (index) => {
        const rowToDelete = offersRows[index];
        if (rowToDelete.InsurancePremiumId) {
            try {
                const deleteResponse = await fetchDeleteData(`InsurancePremium/delete/${rowToDelete.InsurancePremiumId}`);

                if (!deleteResponse) {
                    throw new Error(`Failed to delete insurance premium with ID: ${rowToDelete.InsurancePremiumId}`);
                }

                const updatedRows = offersRows.filter((row, i) => i !== index);
                setOffersRows(updatedRows.map((row, i) => ({ ...row, SLNo: i + 1 })));

            } catch (error) {
                console.error('Error deleting row:', error.message);
                alert('Failed to delete the row, please try again.');
            }
        } else {
            const updatedRows = offersRows.filter((row, i) => i !== index);
            setOffersRows(updatedRows.map((row, i) => ({ ...row, SLNo: i + 1 })));
        }
    };

    const deleteAdditionalRow = (index) => {
        const updatedRows = additionalRows.filter((row, i) => i !== index);
        const renumberedRows = updatedRows.map((row, i) => ({ ...row, SLNo: i + 1 }));
        setAdditionalRows(renumberedRows);
    };

    const handleAdditionalInputChange = (index, fieldName, value) => {
        let newValue = value;

        if (fieldName === 'LabelName') {
            newValue = value.toString();
        } else {
            if (newValue === '' || isNaN(newValue)) {
                newValue = '';
            }
        }
        const updatedRows = [...additionalRows];
        updatedRows[index][fieldName] = newValue;

        const inflatedRate = parseFloat(updatedRows[index]['Inflated Rate']) || 0;
        const taxPercentage = parseFloat(updatedRows[index]['Tax %']) || 0;
        const otherCharges = parseFloat(updatedRows[index]['Other Charges']) || 0;

        if (!isNaN(inflatedRate) && !isNaN(taxPercentage) && !isNaN(otherCharges)) {
            const totalAmount = inflatedRate + (inflatedRate * (taxPercentage / 100)) + otherCharges;
            updatedRows[index]['Total Amount'] = totalAmount.toFixed(2);
        } else {
            updatedRows[index]['Total Amount'] = '0';
        }

        setAdditionalRows(updatedRows);

        calculateTotalAmount();
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleInputChange = (index, fieldName, value) => {
        let newValue = value;
        let totalAmount;

        if (fieldName === 'BasePremium') {
            if (/^\d*\.?\d*$/.test(value)) {
                newValue = value;
            } else {
                return;
            }
        } else if (fieldName === 'MinimumAge' || fieldName === 'MaximumAge') {
            if (value > 99) {
                return;
            } else {
                newValue = value;
            }
            /*const newValueStr = value.toString().slice(0, 2);
            newValue = parseInt(newValueStr, 10);*/
        } else if (fieldName === 'GST') {
            if (value > 99) {
                return;
            } else {
                newValue = value;
            }
        }
        /*if (newValue === '' || isNaN(newValue)) {
            newValue = '';
        }*/

        let updatedRows = [...rows];
        updatedRows[index][fieldName] = newValue;
        /*setRows(updatedRows);*/

        let basePremium = parseFloat(updatedRows[index]['BasePremium']) || 0;
        const gst = parseFloat(updatedRows[index]['GST']) || 0;

        /*if (!isNaN(basePremium) && !isNaN(gst))*/
        if (fieldName === 'GST') {
            totalAmount = parseInt(updatedRows[index]['TotalAmount']) || 0;
            if (totalAmount > 0) {
                basePremium = (totalAmount * 100) / (100 + gst);
                updatedRows[index]['BasePremium'] = basePremium.toFixed(2);
            } else if (basePremium > 0) {
                const gstAmount = (basePremium * gst) / 100;
                totalAmount = basePremium + gstAmount;
                updatedRows[index]['TotalAmount'] = parseInt(totalAmount);
            }
        } else if (fieldName === 'BasePremium') {
            if (basePremium > 0) {
                const gstAmount = (basePremium * gst) / 100;
                totalAmount = basePremium + gstAmount;
                updatedRows[index]['TotalAmount'] = parseInt(totalAmount);
            } else {
                updatedRows[index]['TotalAmount'] = 0;
            }
        } else if (fieldName === 'TotalAmount') {
            totalAmount = parseInt(newValue) || 0;
            if (totalAmount > 0) {
                basePremium = (totalAmount * 100) / (100 + gst);
                updatedRows[index]['BasePremium'] = basePremium.toFixed(2);
            } else {
                updatedRows[index]['BasePremium'] = 0;
            }
        }

        if (additionalRows.length > 0 && additionalRows[0].Value) {
            totalAmount = parseInt(updatedRows[index]['TotalAmount']) || 0;
            additionalRows.map(row => {
                totalAmount += parseFloat(row.Value);
            })
            updatedRows[index]['TotalAmount'] = parseInt(totalAmount);
        }

        setRows(updatedRows);

        if (offersRows.length > 0) {
            offersRows.map(row => row.TotalAmount = parseInt(totalAmount));
        }

        /*calculateTotalAmount();*/

        setFormData((preVal) => ({
            ...preVal,
            SaleAmount: totalAmount
        }))
    };

    const handleOffersChange = (index, fieldName, value) => {
        let updatedRows = [...offersRows];
        updatedRows[index][fieldName] = value;

        let totalAmount = parseInt(rows[0].TotalAmount);

        if (fieldName === 'OfferAmount') {
            if (parseInt(value) > 0 && totalAmount > 0 && parseInt(value) < totalAmount) {
                let percentage = 100 - (value / totalAmount * 100);

                updatedRows[index]['OfferPercentage'] = percentage.toFixed(2);

                setOffersFormError('');
            } else {
                setOffersFormError('Offer Amount must be less than Total Amount');
            }
        } else if (fieldName === 'OfferPercentage') {
            if (parseInt(value) > 0 && totalAmount > 0 && parseInt(value) < 100) {
                let offerAmount = totalAmount - (value / 100 * totalAmount);

                updatedRows[index]['OfferAmount'] = offerAmount.toFixed(2)

                setOffersFormError('');
            } else {
                if (!totalAmount || totalAmount <= 0) {
                    setOffersFormError('Please Enter Total Amount');
                } else {
                    setOffersFormError('Offer Percentage must ranges between 0 to 100');
                }

            }
        }

        setOffersRows(updatedRows);
    };

    useEffect(() => {
        calculateTotalAmount();
    }, [additionalRows]);

    useEffect(() => {
        const getProducts = async () => {
            try {
                const response = await fetchData('Products/all', { skip: 0, take: 0 });

                setAllProducts(response);
                let getIndividualPrd = [];

                response && response.length > 0 && response.map(each => {
                    if (!each.IsCombo) {
                        getIndividualPrd = [...getIndividualPrd, each];
                    }
                })

                let prods = [];
                {
                    getIndividualPrd && getIndividualPrd.length > 0 && getIndividualPrd.map(data => {
                        prods = [...prods, { label: data.ProductName, value: data.ProductsId }]
                    })
                }

                setProdNames(prods);
            } catch (e) {
                console.error("Error fetching products: ", e);
            }
        }

        getProducts();
    }, []);

    const calculateTotalAmount = () => {
        let finalTotalAmount = 0;
        let fixedTotal = 0;
        let variableTotal = 0;
        additionalRows.forEach(row => {
            const amount = parseFloat(row.Value) || 0;
            if (!isNaN(amount)) {
                if (row.IsFixed) {
                    fixedTotal += amount;
                } else {
                    variableTotal += amount;
                }
            }
        });

        rows.forEach((row, index) => {
            const basePremium = parseFloat(row.BasePremium) || 0;
            const variableAmount = variableTotal > 0 ? (basePremium / variableTotal) * 2 : 0;
            const totalBeforeGST = basePremium + fixedTotal + variableAmount;
            const gst = parseFloat(row.GST) || 0;
            const gstAmount = (totalBeforeGST * gst) / 100;
            const totalAmount = totalBeforeGST + gstAmount;
            rows[index].TotalAmount = totalAmount.toFixed(2);
            if (!isNaN(totalAmount)) {
                finalTotalAmount += totalAmount;
            }
        });

        return finalTotalAmount;

    };

    useEffect(() => {
        if (profileFromLocation) {
            setFormData({
                ProductName: profileFromLocation[0].ProductName,
                Image: profileFromLocation[0].Image,
                LongDescription: profileFromLocation[0].LongDescription,
                ShortDescription: profileFromLocation[0].ShortDescription,
                MaximumAdult: profileFromLocation[0].MaximumAdult,
                MaximumChild: profileFromLocation[0].MaximumChild,
                ServiceProvider: profileFromLocation[0].ServiceProvider,
                MinimumAge: profileFromLocation[0].MinimumAge,
                MaximumAge: profileFromLocation[0].MaximumAge,
                ChildrenAge: profileFromLocation[0].ChildrenAge,
                WelcomeEmail: profileFromLocation[0].WelcomeEmail,
                KeyFeatures: profileFromLocation[0].KeyFeatures,
                IsDefault: profileFromLocation[0].IsDefault,
                IsFree: profileFromLocation[0].IsFree,
                IsActive: profileFromLocation[0].IsActive,
                IsNomineeRequired: profileFromLocation[0].IsNomineeRequired,
                EndorseEmail: profileFromLocation[0].EndorseEmail,
                ProductCategoryId: profileFromLocation[0].ProductCategoryId,
                SumAssured: profileFromLocation[0].SumAssured,
                ProductsId: profileFromLocation[0].ProductsId,
                SaleAmount: profileFromLocation[0].SaleAmount,
                IsCombo: profileFromLocation[0].IsCombo,
                MaximumMembers: profileFromLocation[0].MaximumMembers,
                IsCorporate: profileFromLocation[0].IsCorporate
            });

            if (profileFromLocation[0].IsCombo) {
                const getSelectedProducts = async () => {
                    const response = await fetchAllData(`ComboProducts/FetchComboProducts/${profileFromLocation[0].ProductsId}`);

                    let selectedList = [];
                    response && response.length > 0 && response.map(prod => (
                        selectedList = [...selectedList, { label: prod.ProductName, value: prod.ProductsId }]
                    ))

                    setSelectedProducts(selectedList);
                }

                getSelectedProducts();
            }

            // Map through the insurance data and create rows for each
            const insuranceRows = profileFromLocation[0].InsurancePremiums.map((insurance, index) => ({

                SLNo: index + 1,
                MinimumAge: insurance.MinimumAge,
                MaximumAge: insurance.MaximumAge,
                BasePremium: insurance.BasePremium,
                GST: insurance.GST,
                TotalAmount: insurance.TotalAmount,
                InsurancePremiumId: insurance.InsurancePremiumId
            }));
            setRows(insuranceRows);

            // Map through the insurance data and create rows for each
            const otherChargesRows = otherChargesFromLocation.map((charges, index) => ({
                SLNo: index + 1,
                LabelName: charges.LabelName,
                Value: charges.Value,
                IsFixed: charges.IsFixed,
                CreatedBy: charges.CreatedBy,
                OtherChargesId: charges.OtherChargesId,
            }));
            setAdditionalRows(otherChargesRows);

            const offersRows = profileFromLocation[0].ProductsOffers.map((offers, index) => ({
                SLNo: index + 1,
                MemberTypeId: offers.MemberTypeId,
                OfferAmount: offers.OfferAmount,
                OfferPercentage: offers.OfferPercentage,
                TotalAmount: profileFromLocation[0].InsurancePremiums[0].TotalAmount,
                ProductsOffersId: offers.ProductsOffersId,
            }));
            setOffersRows(offersRows);

        } else {
            setIsAddForm(true);
            setFormData(initialFormData.productFormData);
            setRows([initialFormData.insurancePremiumFormData]);
            setAdditionalRows([initialFormData.otherChargesFormData]);
            setOffersRows([]);
        }

    }, [profileFromLocation]);

    const validateForm = () => {
        let err = {};

        if (!formData.ProductName || formData.ProductName.trim() === '') {
            err.ProductName = 'Please Enter Product Name';
        }
        if (!formData.ShortDescription || formData.ShortDescription.trim() === '') {
            err.ShortDescription = 'Please Enter Short Description';
        }
        if (!formData.ServiceProvider || formData.ServiceProvider.trim() === '') {
            err.ServiceProvider = 'Please Enter Service Provider';
        }

        if (formData.MaximumAdult === 'string' && formData.MaximumAdult.trim() === '') {
            err.MaximumAdult = 'Please Enter Maximum Adult';
        } else {
            if (formData.MaximumAdult.length === 0) {
                err.MaximumAdult = 'Please Enter Maximum Adult';
            }
        }

        if (formData.MaximumMembers === 'string' && formData.MaximumMembers.trim() === '') {
            err.MaximumMembers = 'Please Enter Maximum Members';
        } else {
            if (formData.MaximumMembers.length === 0) {
                err.MaximumMembers = 'Please Enter Maximum Members';
            }
        }

        if (formData.IsCombo) {
            if (selectedProducts.length <= 1) {
                err.SelectedProducts = 'Please Select atleast 2 products'
            }
        }

        if (profileFromLocation) {
            if (!formData.MinimumAge || formData.MinimumAge === '') {
                err.MinimumAge = 'Please Enter Minimum Age';
            } else if (isNaN(formData.MinimumAge) || parseInt(formData.MinimumAge) < 17) {
                err.MinimumAge = 'Minimum Age Must be 18 Atleast';
            }
        } else {
            if (!formData.MinimumAge || formData.MinimumAge.trim() === '') {
                err.MinimumAge = 'Please Enter Minimum Age';
            } else if (isNaN(formData.MinimumAge) || parseInt(formData.MinimumAge) < 17) {
                err.MinimumAge = 'Minimum Age Must be 18 Atleast';
            }
        }

        if (profileFromLocation) {
            if (!formData.MaximumAge || formData.MaximumAge === '') {
                err.MaximumAge = 'Please Enter Maximum Age';
            } else if (isNaN(formData.MaximumAge) || parseInt(formData.MaximumAge) > 80) {
                err.MaximumAge = 'Maximum Age Must be less than 80';
            }
        } else {
            if (!formData.MaximumAge || formData.MaximumAge.trim() === '') {
                err.MaximumAge = 'Please Enter Maximum Age';
            } else if (isNaN(formData.MaximumAge) || parseInt(formData.MaximumAge) > 80) {
                err.MaximumAge = 'Maximum Age Must be less than 80';
            }
        }
        if (formData.MinimumAge && formData.MaximumAge) {
            const minAge = parseInt(formData.MinimumAge, 18);
            const maxAge = parseInt(formData.MaximumAge, 80);
            if (!isNaN(minAge) && !isNaN(maxAge) && maxAge <= minAge) {
                err.MaximumAge = 'Maximum Age is Greater Than The Minimum age';
            }
        }

        if (!profileFromLocation) {
            if (!formData.ProductCategoryId || typeof formData.ProductCategoryId !== 'string' || formData.ProductCategoryId.trim() === '') {
                err.ProductCategoryId = 'Please Select a Product Category';
            }
        } else {
            if (formData.ProductCategoryId.length === 0) {
                err.ProductCategoryId = 'Please Select a Product Category';
            }
        }

        if (rows.length === 0 || rows.some(row => !row.MinimumAge || !row.MaximumAge || !row.BasePremium || !row.GST || !row.TotalAmount)) {
            err.InsurancePremium = 'At least one insurance premium row is required';
        }

        if (offersRows.length >= 1 && offersRows.some(row => !row.MemberTypeId || !row.OfferPercentage || !row.OfferAmount)) {
            err.Offers = 'Fill all the data or delete the row'
        }

        setFormError({ ...err });
        const isValid = Object.keys(err).length === 0;
        return isValid;
    }

    const onChangeHandler = (e) => {
        const { name, type, checked, value, files, id } = e.target;
        if (["MinimumAge", "MaximumAge", "MaximumAdult", "MaximumChild", "ChildrenAge", "MaximumMembers"].includes(name) && !/^\d*$/.test(value)) {
            return;
        }

        if (type === 'radio') {
            setFormData((prevState) => ({
                ...prevState,
                [name]: id.includes('Yes') ? true : false,
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: type === 'checkbox' ? checked : value,
            }));
        }

        if (files && files.length > 0) {
            const file = files[0];
            setSelectedImage(file);
        }
    };

    const onQuillChangeHandler = (field) => (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    useEffect(() => {
        const getProductCategory = async () => {
            const productsData = await fetchData("ProductCategory/all", { "skip": 0, "take": 0 });
            setProducts(productsData);
        }

        const getMemberTypes = async () => {
            const memberTypesReponse = await fetchData("MemberTypes/all", { 'skip': 0, 'take': 0 });
            setMemberTypes(memberTypesReponse);
        }

        getProductCategory();
        getMemberTypes();
    }, []);

    const handleFileSelection = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const handleFileUpload = async (ProductsId) => {
        try {
            const formData = new FormData();
            formData.append('ProductsId', ProductsId);
            formData.append('Image', selectedImage);

            const response = await uploadImage('Products/upload', formData);

            return response;
        } catch (error) {
            console.error('Error uploading files:', error);
            throw error;
        }
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            try {
                let ProductsData;
                let PProductsId;

                const productPayload = {
                    productname: formData.ProductName,
                    image: formData.Image,
                    longdescription: formData.LongDescription,
                    shortdescription: formData.ShortDescription,
                    serviceprovider: formData.ServiceProvider,
                    maximumAdult: formData.MaximumAdult || 0,
                    maximumChild: formData.MaximumChild || 0,
                    minimumAge: formData.MinimumAge,
                    maximumAge: formData.MaximumAge,
                    childrenAge: formData.ChildrenAge || 0,
                    isNomineeRequired: formData.IsNomineeRequired,
                    WelcomeEmail: formData.WelcomeEmail,
                    KeyFeatures: formData.KeyFeatures,
                    isDefault: formData.IsDefault,
                    isActive: formData.IsActive,
                    isFree: formData.IsFree,
                    endorseEmail: formData.EndorseEmail,
                    ProductCategoryId: formData.ProductCategoryId,
                    saleAmount: formData.SaleAmount,
                    isCombo: formData.IsCombo,
                    maximumMembers: formData.MaximumMembers,
                    IsCorporate: formData.IsCorporate
                };

                if (formData.SumAssured) {
                    productPayload.sumAssured = formData.SumAssured;
                }

                if (isAddForm) {
                    ProductsData = await fetchData("Products/add", productPayload);

                    if (formData.IsCombo && ProductsData.status) {
                        let payload = [];
                        {
                            selectedProducts.map(prod => {
                                payload = [...payload, { ComboId: ProductsData.returnData.productsId, ProductsId: prod.value }]
                            })
                        }
                        await fetchData('ComboProducts/seed', payload);
                    }
                    setSnackbarMessage("Product Added Successfully");

                } else {
                    productPayload.productsId = formData.ProductsId;
                    ProductsData = await fetchUpdateData("Products/update", productPayload);

                    if (formData.IsCombo && ProductsData) {
                        let payload = [];
                        {
                            selectedProducts.map(prod => {
                                payload = [...payload, { ComboId: ProductsData.productsId, ProductsId: prod.value }]
                            })
                        }
                        await fetchData('ComboProducts/updateComboProducts', payload);
                    }
                    setSnackbarMessage("Product updated Successfully");
                }

                if (ProductsData.message === 'This Product already exists') {
                    setFormError(prevState => ({
                        ...prevState,
                        ProductName: 'This Product already exists'
                    }));
                    return;
                }

                if (!profileFromLocation) {
                    PProductsId = ProductsData.returnData.productsId;
                } else {
                    PProductsId = ProductsData.productsId;
                }

                const insurancePremiumArray = rows.map(row => ({
                    InsurancePremiumId: row.InsurancePremiumId,
                    MinimumAge: row.MinimumAge,
                    MaximumAge: row.MaximumAge,
                    BasePremium: row.BasePremium,
                    GST: row.GST,
                    productsId: PProductsId,
                    TotalAmount: parseFloat(row.TotalAmount)
                }));

                const otherChargesArray = additionalRows.map(row => ({
                    OtherChargesId: row.OtherChargesId,
                    productsId: PProductsId,
                    labelName: row.LabelName,
                    value: isNaN(parseFloat(row.Value)) ? row.Value : parseFloat(row.Value),
                    isFixed: row.IsFixed === true,
                }));

                if (offersRows.length > 0) {
                    // Check if any row has a `ProductsOffersId` (indicating it's an update)
                    const isUpdate = offersRows.some(row => row.ProductsOffersId);

                    if (!isUpdate) {
                        // Insert logic
                        const offersPayload = offersRows.map(row => ({
                            productsId: PProductsId,
                            memberTypeId: row.MemberTypeId,
                            offerPercentage: row.OfferPercentage,
                            offerAmount: row.OfferAmount,
                            userId: UserId,
                        }));

                        const offersResponse = await fetchData('ProductsOffers/seedToInsert', offersPayload);

                    } else {
                        // Update logic
                        const offersPayload = offersRows.map(row => ({
                            productsOffersId: row.ProductsOffersId,
                            productsId: PProductsId,
                            memberTypeId: row.MemberTypeId,
                            offerPercentage: row.OfferPercentage,
                            offerAmount: row.OfferAmount,
                            userId: UserId,
                        }));

                        const offersResponse = await fetchUpdateData('ProductsOffers/seedToUpdate', offersPayload);
                    }
                }

                const deletedInsurancePremiumIds = rows.filter(row => row.markedForDeletion).map(row => row.InsurancePremiumId);

                if (deletedInsurancePremiumIds.length > 0) {
                    for (const id of deletedInsurancePremiumIds) {
                        const deleteResponse = await fetchDeleteData(`InsurancePremium/delete/${id}`);
                        if (!deleteResponse) throw new Error(`Failed to delete insurance premium with ID: ${id}`);
                    }
                }

                if (insurancePremiumArray.some(item => item.MinimumAge || item.MaximumAge || item.BasePremium || item.GST || item.InsurancePremiumId)) {
                    for (const item of insurancePremiumArray) {
                        let response;
                        if (item.InsurancePremiumId) {
                            response = await fetchUpdateData("InsurancePremium/update", item);
                        } else {
                            response = await fetchData("InsurancePremium/add", item);
                        }
                        if (!response) throw new Error("Failed to save insurance premium");
                    }
                }

                if (otherChargesArray.some(item => item.labelName || item.value)) {
                    for (const item of otherChargesArray) {
                        let response;
                        if (item.OtherChargesId) {
                            response = await fetchUpdateData("OtherCharges/update", [{ item }]);
                        } else {
                            response = await fetchData("OtherCharges/add", [{ item }]);
                        }
                        if (!response) throw new Error("Failed to save other charges");
                    }
                }


                let ProductsId;
                if (ProductsData && ProductsData.returnData && ProductsData.returnData.productsId) {
                    ProductsId = ProductsData.returnData.productsId;
                } else if (ProductsData && ProductsData.productsId) {
                    ProductsId = ProductsData.productsId;
                } else {
                    throw new Error('ProductsId is not available.');
                }

                if (!ProductsId) {
                    throw new Error('ProductsId is undefined');
                }

                // if (selectedImage) {
                //     await handleFileUpload(ProductsId);
                // }

                setTimeout(() => {
                    navigate(`/products/details/${PProductsId}`);
                }, 3000);

                setSnackbarOpen(true);
            } catch (error) {
                console.error('Error in onSubmitHandler:', error.message);
                alert("Failed to save data, please try again.");
            }
        }
    };

    const handleBackToView = () => {
        navigate(`/products/list`);
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setSelectedImage(null);
        /*clearErrorMessages();*/
    };

    const handleRadioChange = (index, value) => {
        const updatedRows = [...additionalRows];
        updatedRows[index].IsFixed = value === 'fixed';
        setAdditionalRows(updatedRows);
    };

    useEffect(() => {
        rows.forEach(row => {
            const basePremium = parseFloat(row['basePremium']) || 0;
            const GST = parseFloat(row['gst']) || 0;
            const gstAmount = (basePremium * GST) / 100;
            const totalAmountWithGST = basePremium + gstAmount;
            const otherChargesForCurrentRow = additionalRows
                .filter(additionalRow => additionalRow['rowId'] === row['id'])
                .reduce((acc, additionalRow) => acc + parseFloat(additionalRow['Amount'] || 0), 0);
            const rowTotal = totalAmountWithGST + otherChargesForCurrentRow;
            row['totalAmount'] = rowTotal.toFixed(2);
        });

    }, [rows, additionalRows]);

    return (
        <>
            <div>
                <form className="mx-1" onSubmit={onSubmitHandler}>
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5>Product Info</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="ProductName" className="form-label">
                                            Product Name <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="ProductName"
                                            name="ProductName"
                                            className="form-control"
                                            placeholder="Product Name"
                                            maxLength={100}
                                            value={formData.ProductName || ''}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.ProductName && (
                                            <div className="text-danger mt-1">{formError.ProductName}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="ShortDescription" className="form-label">
                                            Short Description <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="ShortDescription"
                                            name="ShortDescription"
                                            className="form-control"
                                            maxLength={200}
                                            placeholder="Short Description"
                                            value={formData.ShortDescription || ''}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.ShortDescription && (
                                            <div className="text-danger mt-1">{formError.ShortDescription}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="ServiceProvider" className="form-label">
                                            Service Provider <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="ServiceProvider"
                                            name="ServiceProvider"
                                            className="form-control"
                                            placeholder="Service Provider"
                                            value={formData.ServiceProvider || ''}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.ServiceProvider && (
                                            <div className="text-danger mt-1">{formError.ServiceProvider}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="ProductCategoryId" className="form-label">
                                            Product Category <span className="text-danger">*</span>
                                        </label>
                                        <select
                                            id="ProductCategoryId"
                                            name="ProductCategoryId"
                                            className="form-select"
                                            value={formData.ProductCategoryId || ''}
                                            onChange={onChangeHandler}
                                        >
                                            <option value="">--Select an option--</option>
                                            {products &&
                                                products.map((option, index) => (
                                                    <option key={index} value={option.ProductCategoryId}>
                                                        {option.ProductCategoryName}
                                                    </option>
                                                ))}
                                        </select>
                                        {formError.ProductCategoryId && (
                                            <div className="text-danger mt-1">{formError.ProductCategoryId}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="ProductCategoryId" className="form-label">
                                            Is Combo <span className="text-danger">*</span>
                                        </label>
                                        <div>
                                            <input
                                                type='radio' className='me-1' name='IsCombo' id='IsCombYes'
                                                onChange={onChangeHandler} value={formData.IsCombo || ''}
                                                checked={formData.IsCombo === true}
                                            />
                                            <label htmlFor="IsCombYes" className='me-5'>Yes</label>
                                            <input
                                                type='radio' className='me-1' name='IsCombo' id='IsCombNo'
                                                onChange={onChangeHandler} value={formData.IsCombo || ''}
                                                checked={formData.IsCombo === false}
                                            />
                                            <label htmlFor="IsCombNo">No</label>
                                        </div>
                                        {formError.IsCombo && (
                                            <div className="text-danger mt-1">{formError.IsCombo}</div>
                                        )}
                                    </div>
                                </div>

                                {formData.IsCombo && (
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="selectProducts" className="form-label">
                                                Products <span className="text-danger">*</span>
                                            </label>
                                            <div className="selectProducts">
                                                {allProducts && (
                                                    <MultiSelect
                                                        options={prodNames}
                                                        value={selectedProducts}
                                                        onChange={setSelectedProducts}
                                                    />
                                                )}
                                            </div>
                                            {formError.SelectedProducts && (
                                                <div className="text-danger mt-1">{formError.SelectedProducts}</div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="TotalAmount" className="form-label">
                                            Sum Assured
                                        </label>
                                        <input
                                            type="text"
                                            id="SumAssured"
                                            name="SumAssured"
                                            className="form-control"
                                            placeholder="SumAssured Amount"
                                            maxLength={10}
                                            value={formData.SumAssured || ''}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.SumAssured && (
                                            <div className="text-danger mt-1">{formError.SumAssured}</div>
                                        )}
                                    </div>
                                </div>

                                {/* <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="Image" className="form-label">Upload Image <span className="text-danger"></span></label>
                                        <div className="col-12 fw-normal" style={{ marginBottom: '10px' }}>
                                            {selectedImage && (
                                                <a href={URL.createObjectURL(selectedImage)} target="_blank" rel="noopener noreferrer">
                                                    <img className="selected-image img-thumbnail me-3" src={URL.createObjectURL(selectedImage)} alt="Selected Front" style={{ width: '150px', height: '100px', objectFit: 'cover' }} />
                                                </a>
                                            )}
                                            <input className="form-control" type="file" name="Image" onChange={(e) => handleFileSelection(e)} style={{ marginTop: '10px' }} />
                                        </div>

                                        {formError.Image && <div className="text-danger mt-1">{formError.Image}</div>}
                                    </div>
                                </div> */}

                                <div className="col-md-6">
                                    <div className="mb-3">
                                        <label htmlFor="IsCorporate" className="form-label">
                                            Is CORPORATE <span className="text-danger">*</span>
                                        </label>
                                        <div>
                                            <input
                                                type='radio' className='me-1' name='IsCorporate' id='IsCorporateYes'
                                                onChange={onChangeHandler} value={formData.IsCorporate || ''}
                                                checked={formData.IsCorporate === true}
                                            />
                                            <label htmlFor="IsCorporateYes" className='me-5'>Yes</label>
                                            <input
                                                type='radio' className='me-1' name='IsCorporate' id='IsCorporateNo'
                                                onChange={onChangeHandler} value={formData.IsCorporate || ''}
                                                checked={formData.IsCorporate === false}
                                            />
                                            <label htmlFor="IsCorporateNo">No</label>
                                        </div>
                                        {formError.IsCorporate && (
                                            <div className="text-danger mt-1">{formError.IsCorporate}</div>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Member Management Card */}
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5>Primary Member</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="MaximumAdult" className="form-label">
                                            Maximum Adult <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="MaximumAdult"
                                            maxLength="2"
                                            name="MaximumAdult"
                                            className="form-control"
                                            placeholder="Maximum Adult"
                                            value={formData.MaximumAdult || ''}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.MaximumAdult && (
                                            <div className="text-danger mt-1">{formError.MaximumAdult}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="MaximumChild" className="form-label">
                                            Maximum Child
                                        </label>
                                        <input
                                            type="text"
                                            id="MaximumChild"
                                            maxLength="2"
                                            name="MaximumChild"
                                            className="form-control"
                                            placeholder="Maximum Child"
                                            value={formData.MaximumChild || ''}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.MaximumChild && (
                                            <div className="text-danger mt-1">{formError.MaximumChild}</div>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="MaximumMembers" className="form-label">
                                            Maximum Members <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="MaximumMembers"
                                            maxLength="2"
                                            name="MaximumMembers"
                                            className="form-control"
                                            placeholder="Maximum Members"
                                            value={formData.MaximumMembers || ''}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.MaximumMembers && (
                                            <div className="text-danger mt-1">{formError.MaximumMembers}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Age Management Card */}
                    <div className="card mb-3">
                        <div className="card-header">
                            <h5>Age Management</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="MinimumAge" className="form-label">
                                            Minimum Adult Age <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="MinimumAge"
                                            maxLength="2"
                                            name="MinimumAge"
                                            className="form-control"
                                            placeholder="Minimum Age"
                                            value={formData.MinimumAge}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.MinimumAge && (
                                            <div className="text-danger mt-1">{formError.MinimumAge}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="MaximumAge" className="form-label">
                                            Maximum Adult Age <span className="text-danger">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            id="MaximumAge"
                                            maxLength="2"
                                            name="MaximumAge"
                                            className="form-control"
                                            placeholder="Maximum Age"
                                            value={formData.MaximumAge || ''}
                                            onChange={onChangeHandler}
                                        />
                                        {formError.MaximumAge && (
                                            <div className="text-danger mt-1">{formError.MaximumAge}</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="ChildrenAge" className="form-label">
                                            Children Age
                                        </label>
                                        <input
                                            type="text"
                                            id="ChildrenAge"
                                            name="ChildrenAge"
                                            maxLength="2"
                                            className="form-control"
                                            placeholder="Children Age"
                                            value={formData.ChildrenAge || ''}
                                            onChange={onChangeHandler}
                                            disabled={!formData.MaximumChild}
                                        />
                                        {formError.ChildrenAge && (
                                            <div className="text-danger mt-1">{formError.ChildrenAge}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-header">
                            <h5>Additional Options</h5>
                        </div>
                        <div className="card-body">
                            <div className="row mx-2 mt-2">
                                <div className="col-3">
                                    <label className="form-label">Is Nominee Required</label>
                                    <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="IsNomineeRequired"
                                            id="IsNomineeRequiredCheckbox"
                                            checked={formData.IsNomineeRequired}
                                            onChange={(e) => onChangeHandler(e)} />
                                        <label className="form-check-label ml-2" htmlFor="IsNomineeRequiredCheckbox" style={{ marginLeft: '5px' }}>
                                            Yes, nominee is required
                                        </label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <label className="form-label">Is Default</label>
                                    <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="IsDefault"
                                            id="IsDefaultCheckbox"
                                            checked={formData.IsDefault}
                                            onChange={(e) => onChangeHandler(e)} />
                                        <label className="form-check-label ml-2" htmlFor="IsDefaultCheckbox" style={{ marginLeft: '5px' }}>
                                            Default
                                        </label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <label className="form-label">Is Free</label>
                                    <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="IsFree"
                                            id="IsFreeCheckbox"
                                            checked={formData.IsFree}
                                            onChange={(e) => onChangeHandler(e)} />
                                        <label className="form-check-label ml-2" htmlFor="IsFreeCheckbox" style={{ marginLeft: '5px' }}>
                                            Free/Purchase
                                        </label>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <label className="form-label">Is Active</label>
                                    <div className="form-check" style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            name="IsActive"
                                            id="IsActiveCheckbox"
                                            checked={formData.IsActive}
                                            onChange={(e) => onChangeHandler(e)} />
                                        <label className="form-check-label ml-2" htmlFor="IsActiveCheckbox" style={{ marginLeft: '5px' }}>
                                            Active or Not
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-2">
                        <h5 className="card-header bg-primary text-white">Premium Table</h5>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">SL No</th>
                                            <th scope="col">Min Age</th>
                                            <th scope="col">Max Age</th>
                                            <th scope="col">Base Premium Amount</th>
                                            <th scope="col">GST%</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.map((row, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        min="18"
                                                        type='number'
                                                        value={row.MinimumAge}
                                                        onChange={(e) => handleInputChange(index, 'MinimumAge', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        min="18"
                                                        type='number'
                                                        value={row.MaximumAge}
                                                        onChange={(e) => handleInputChange(index, 'MaximumAge', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={row.BasePremium}
                                                        onChange={(e) => handleInputChange(index, 'BasePremium', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type='number'
                                                        min='0'
                                                        max='99'
                                                        style={{ width: '100px' }}
                                                        value={row.GST}
                                                        onChange={(e) => handleInputChange(index, 'GST', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        value={row.TotalAmount}
                                                        type='number'
                                                        min='0'
                                                        onChange={(e) => handleInputChange(index, 'TotalAmount', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger"
                                                        onClick={() => deleteRow(index)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {formError.InsurancePremium && <div className="alert alert-danger mt-2">{formError.InsurancePremium}</div>}
                    </div>

                    <div className="text-center mt-2">
                        <button className="btn btn-primary" onClick={addRow}>Add Row</button>
                    </div>

                    {/*Offers*/}
                    <div className="card mt-2">
                        <h5 className="card-header bg-primary text-white">Offers</h5>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">SL No</th>
                                            <th scope="col">Member Type</th>
                                            <th scope="col">Offer Amount</th>
                                            <th scope="col">Offer Percentage</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {offersRows.map((row, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <select
                                                        id="ProductCategoryId"
                                                        name="ProductCategoryId"
                                                        className="form-select"
                                                        value={row.MemberTypeId}
                                                        onChange={(e) => handleOffersChange(index, 'MemberTypeId', e.target.value)}
                                                    >
                                                        <option value="">--Select--</option>
                                                        {memberTypes &&
                                                            memberTypes.map((option, index) => (
                                                                <option key={index} value={option.MemberTypeId}>
                                                                    {option.Type}
                                                                </option>
                                                            ))}
                                                    </select>
                                                </td>
                                                <td>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        min='0'
                                                        step="0.01"
                                                        value={row.OfferAmount}
                                                        onChange={(e) => handleOffersChange(index, 'OfferAmount', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type='number'
                                                        min='0'
                                                        max='99'
                                                        step="0.01"
                                                        style={{ width: '100px' }}
                                                        value={row.OfferPercentage}
                                                        onChange={(e) => handleOffersChange(index, 'OfferPercentage', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        value={row.TotalAmount}
                                                        type='number'
                                                        min='0'
                                                        disabled
                                                        onChange={(e) => handleOffersChange(index, 'TotalAmount', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger"
                                                        onClick={() => deleteOffersRow(index)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {formError.Offers && <div className="alert alert-danger mt-2">{formError.Offers}</div>}
                        {offersFormError && offersFormError.length > 0 && <div className="alert alert-danger mt-2">{offersFormError}</div>}
                    </div>

                    <div className="text-center mt-2">
                        <button className="btn btn-primary" onClick={addOffersRow}>Add Row</button>
                    </div>

                    <div className="card mt-3">
                        <h5 className="card-header bg-primary text-white">Other Charges</h5>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped table-hover">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>SL No</th>
                                            <th>Label</th>
                                            <th>Amount</th>
                                            <th>Type</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {additionalRows.map((row, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={row.LabelName}
                                                        onChange={(e) => handleAdditionalInputChange(index, 'LabelName', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={row.Value}
                                                        onChange={(e) => handleAdditionalInputChange(index, 'Value', e.target.value)}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="form-check form-check-inline mt-3">
                                                        <input
                                                            className="form-check-input"
                                                            id={`fixedRadio${index}`}
                                                            type="radio"
                                                            name={`priceType-${index}`}
                                                            value="fixed"
                                                            checked={row.IsFixed === true}
                                                            onChange={() => handleRadioChange(index, 'fixed')}
                                                        />
                                                        <label className="form-check-label" htmlFor={`fixedRadio${index}`}>
                                                            Fixed
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline mt-3">
                                                        <input
                                                            className="form-check-input"
                                                            id={`variableRadio${index}`}
                                                            type="radio"
                                                            name={`priceType-${index}`}
                                                            value="variable"
                                                            checked={row.IsFixed === false}
                                                            onChange={() => handleRadioChange(index, 'variable')}
                                                        />
                                                        <label className="form-check-label" htmlFor={`variableRadio${index}`}>
                                                            Variable
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="btn btn-sm btn-danger"
                                                        onClick={() => deleteAdditionalRow(index)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="text-center mt-3">
                        <button className="btn btn-primary" onClick={addAdditionalRow}>Add Row</button>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <div className="card" style={{ border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem" }}>
                                <div className="card-body" style={{ border: "none" }}>
                                    <h5 className="card-title">Welcome Email</h5>
                                    <div className="form-group">
                                        <ReactQuill
                                            className="form-control"
                                            value={formData.WelcomeEmail}
                                            onChange={onQuillChangeHandler('WelcomeEmail')}
                                        />
                                        {formError.WelcomeEmail && (
                                            <span className="text-danger">{formError.WelcomeEmail}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="card" style={{ border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem" }}>
                                <div className="card-body" style={{ border: "none" }}>
                                    <h5 className="card-title">Endorse Email</h5>
                                    <div className="form-group">
                                        <ReactQuill
                                            className="form-control"
                                            value={formData.EndorseEmail}
                                            onChange={onQuillChangeHandler('EndorseEmail')}
                                        />
                                        {formError.WelcomeEmail && (
                                            <span className="text-danger">{formError.EndorseEmail}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="card" style={{ border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem" }}>
                                <div className="card-body" style={{ border: "none" }}>
                                    <h5 className="card-title">Long Description</h5>
                                    <div className="form-group">
                                        <ReactQuill
                                            className="form-control"
                                            value={formData.LongDescription}
                                            onChange={onQuillChangeHandler('LongDescription')}
                                        />
                                        {formError.LongDescription && (
                                            <span className="text-danger">{formError.LongDescription}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 mt-3">
                            <div className="card" style={{ border: "1px solid rgba(0, 0, 0, 0.125)", borderRadius: "0.25rem" }}>
                                <div className="card-body" style={{ border: "none" }}>
                                    <h5 className="card-title">Key Features</h5>
                                    <div className="form-group">
                                        <ReactQuill
                                            className="form-control"
                                            value={formData.KeyFeatures}
                                            onChange={onQuillChangeHandler('KeyFeatures')}
                                        />
                                        {formError.KeyFeatures && (
                                            <span className="text-danger">{formError.KeyFeatures}</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group mt-2" style={{ marginBottom: "50px" }}>
                        <div className="col-md-12 col-md-offset-2 d-flex align-items-end">
                            <button className="btn btn-md btn-primary" type="reset" onClick={resetForm}>
                                Reset
                            </button>
                            <button className="btn btn-danger  ms-auto me-2" type="reset" onClick={handleBackToView}>
                                Cancel
                            </button>
                            <button className="btn btn-md btn-primary" type="submit" style={{
                                backgroundColor: "#5cb85c",
                                borderColor: "#5cb85c",
                                color: "#fff",
                                borderRadius: "5px",
                                padding: "8px 20px",
                                fontWeight: "bold",
                                cursor: "pointer"
                            }}>
                                Submit
                            </button>
                        </div>
                    </div>

                    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                        <Alert onClose={handleSnackbarClose} severity="success">
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </form>
            </div>
        </>
    )
}
