import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { fetchAllData } from "../../helpers/externapi";

export default function ProductPersonalDetails(props) {
    const [profile, setProfile] = useState(props.data || []);
    const [otherCharges, setOtherCharges] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const id = useParams();
    const [comboList, setComboList] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState(null);

    const openModal = (product) => {
        setSelectedProduct(product);
        const modal = new window.bootstrap.Modal(document.getElementById('exLargeModal'));
        modal.show();
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchAllData(`Products/GetAllProductDetailsById/${id.Id}`);
                setProfile(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [id.Id]);

    useEffect(() => {
        const fetchOtherChargesData = async () => {
            setLoading(true);
            try {
                const response = await fetchAllData(`OtherCharges/GetOtherChargesDetailsByProductsId/${id.Id}`);
                setOtherCharges(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchOtherChargesData();
    }, [id.Id]);

    useEffect(() => {
        if (profile.length > 0 && profile[0].IsCombo) {
            const getSelectedProducts = async () => {
                const response = await fetchAllData(`ComboProducts/FetchComboProducts/${profile[0].ProductsId}`);

                setComboList(response);
            };

            getSelectedProducts();
        }

    }, [profile])

    const handleEditForm = () => {
        navigate('/products/new', { state: { profile, otherCharges } });
    };

    const handleBackToList = () => {
        navigate("/products/list");
    };

    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    return (
        <>
            <div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card mb-2">
                            {profile.length > 0 ? (
                                <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-2">                                    
                                    <div className="flex-grow-1 mt-3">
                                        <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                            <div className="user-profile-card p-4">
                                                {/* Header Section */}
                                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
                                                    <h4 className="fw-bold text-primary mb-2 mb-md-0">
                                                        {profile[0].ProductName}
                                                    </h4>
                                                    {profile[0].IsCombo ? (
                                                        <span className="badge bg-success px-3 py-2 fs-6">Combo Product</span>
                                                    ) : (
                                                        <span className="badge bg-warning px-3 py-2 fs-6">Individual Product</span>
                                                    )}
                                                </div>

                                                {/* Product Details and Key Features */}
                                                <div className="row">
                                                    {/* Product Details */}
                                                    <div className="col-12 col-md-6">
                                                        <div className="details-grid row gy-3 gx-4">
                                                            <div className="detail-item col-12">
                                                                <i className="bx bx-male text-primary fs-5"></i>
                                                                <span className="ms-2 fw-bold">Maximum Adult:</span>
                                                                <strong className="ms-2 text-dark">{profile[0].MaximumAdult}</strong>
                                                            </div>
                                                            <div className="detail-item col-12">
                                                                <i className="bx bx-child text-primary fs-5"></i>
                                                                <span className="ms-2 fw-bold">Maximum Child:</span>
                                                                <strong className="ms-2 text-dark">{profile[0].MaximumChild}</strong>
                                                            </div>
                                                            <div className="detail-item col-12">
                                                                <i className="bx bx-calendar-alt text-primary fs-5"></i>
                                                                <span className="ms-2 fw-bold">Minimum Age:</span>
                                                                <strong className="ms-2 text-dark">{profile[0].MinimumAge}</strong>
                                                            </div>
                                                            <div className="detail-item col-12">
                                                                <i className="bx bx-calendar-alt text-primary fs-5"></i>
                                                                <span className="ms-2 fw-bold">Minimum Members:</span>
                                                                <strong className="ms-2 text-dark">{profile[0].MaximumMembers}</strong>
                                                            </div>
                                                            <div className="detail-item col-12">
                                                                <i className="bx bx-calendar-alt text-primary fs-5"></i>
                                                                <span className="ms-2 fw-bold">Maximum Age:</span>
                                                                <strong className="ms-2 text-dark">{profile[0].MaximumAge}</strong>
                                                            </div>
                                                            <div className="detail-item col-12">
                                                                <i className="bx bx-child text-primary fs-5"></i>
                                                                <span className="ms-2 fw-bold">Children Age:</span>
                                                                <strong className="ms-2 text-dark">{profile[0].ChildrenAge}</strong>
                                                            </div>
                                                            <div className="detail-item col-12">
                                                                <i className="bx bx-globe text-primary fs-5"></i>
                                                                <span className="ms-2 fw-bold">Service Provider:</span>
                                                                <strong className="ms-2 text-dark">{profile[0].ServiceProvider}</strong>
                                                            </div>
                                                            <div className="detail-item col-12">
                                                                <i className="bx bx-info-circle text-primary fs-5"></i>
                                                                <span className="ms-2 fw-bold">Short Description:</span>
                                                                <strong className="ms-2 text-dark">{profile[0].ShortDescription}</strong>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* Key Features */}
                                                    <div className="col-12 col-md-6">
                                                        <i className="bx bx-list-ul text-primary fs-5"></i>
                                                        <span className="ms-2 fw-bold">Key Features:</span>
                                                        <ul className="key-features-list text-dark ms-4 mt-2">
                                                            {stripHtmlTags(profile[0].KeyFeatures)
                                                                .split(/[,.;]/)
                                                                .filter((feature) => feature.trim() !== "")
                                                                .map((feature, index) => (
                                                                    <li key={index}>{feature.trim()}</li>
                                                                ))}
                                                        </ul>
                                                    </div>
                                                </div>

                                                {/* Long Description */}
                                                {profile[0].LongDescription && (
                                                    <div className="detail-item mt-4">
                                                        <i className="bx bx-align-left text-primary fs-5"></i>
                                                        <span className="ms-2 fw-bold">Long Description:</span>
                                                        <strong className="ms-2 text-dark">{stripHtmlTags(profile[0].LongDescription)}</strong>
                                                    </div>
                                                )}

                                                {/* Combo Products Section */}
                                                {profile[0].IsCombo && comboList && (
                                                    <div className="combo-products mt-5">
                                                        <h5 className="text-dark fw-bold">Combo Products</h5>
                                                        <ul className="list-unstyled mt-3">
                                                            {comboList.map((item) => (
                                                                <li key={item.ProductsId} className="mt-2">
                                                                    <a
                                                                        href={`/products/details/${item.ProductsId}`}
                                                                        target="_blank"
                                                                        className="text-primary text-dark fw-semibold"
                                                                    >
                                                                        {item.ProductName}
                                                                    </a>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>





                                            {/* <div className="modal fade" id="exLargeModal" tabIndex="-1" aria-hidden="true">
                                                    <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel4">
                                                                    {selectedProduct ? selectedProduct.ProductName : 'Modal title'}
                                                                </h5>
                                                                <button
                                                                    type="button"
                                                                    className="btn-close"
                                                                    data-bs-dismiss="modal"
                                                                    aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">

                                                                {selectedProduct ? (
                                                                    <div>
                                                                        <ul className="list-inline mb-2 d-flex align-items-center flex-wrap justify-content-sm-start justify-content-center gap-2">
                                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                <i className='bx bx-male'></i> Maximum Adult: {selectedProduct.MaximumAdult}
                                                                            </li>
                                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                <i className='bx bx-child'></i> Maximum Child: {selectedProduct.ProductsMaximumChild}
                                                                            </li>
                                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                <i className='bx bx-calendar-alt'></i> Minimum Age: {selectedProduct.ProductsMinimumAge}
                                                                            </li>
                                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                <i className='bx bx-calendar-alt'></i> Maximum Age: {selectedProduct.ProductsMaximumAge}
                                                                            </li>
                                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                <i className='bx bx-child'></i> Children Age: {selectedProduct.ProductsChildrenAge}
                                                                            </li>
                                                                            <li className="list-inline-item col-6 col-sm-4 col-md-3">
                                                                                <i className='bx bx-globe'></i> Service Provider: {selectedProduct.ServiceProvider}
                                                                            </li>
                                                                            <li className="list-inline-item col-6">
                                                                                <i className='bx bx-calendar-alt'></i> Short Description: {selectedProduct.ShortDescription}
                                                                            </li>
                                                                            <li className="list-inline-item col-6">
                                                                                <i className='bx bx-calendar-alt'></i> Long Description: {stripHtmlTags(selectedProduct.LongDescription)}
                                                                            </li>
                                                                        </ul>

                                                                        <div className="row justify-content-center">
                                                                            <div className="col-md-12">
                                                                                <div className="card">
                                                                                    <h5 className="card-header">Insurance Premium Table</h5>
                                                                                    <div className="table-responsive text-nowrap">
                                                                                        <table className="table">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Minimum Age</th>
                                                                                                    <th>Maximum Age</th>
                                                                                                    <th>Base Premium</th>
                                                                                                    <th>GST</th>
                                                                                                    <th>Total Amount</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="table-border-bottom-0">
                                                                                                {selectedProduct.InsurancePremiums && selectedProduct.InsurancePremiums.length > 0 ? (
                                                                                                    selectedProduct.InsurancePremiums.map((item, index) => (
                                                                                                        <tr key={index}>
                                                                                                            <td>{item.InsurancePremiumMinimumAge}</td>
                                                                                                            <td>{item.InsurancePremiumMaximumAge}</td>
                                                                                                            <td>{item.BasePremium}</td>
                                                                                                            <td>{item.GST}</td>
                                                                                                            <td>{item.InsurancePremiumTotalAmount}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="5">No data available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card mt-2">
                                                                                    <h5 className="card-header">Product Offers</h5>
                                                                                    <div className="table-responsive text-nowrap">
                                                                                        <table className="table">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Member Type</th>
                                                                                                    <th>Offer Amount</th>
                                                                                                    <th>Offer Percentage</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="table-border-bottom-0">
                                                                                                {selectedProduct.ProductsOffers && selectedProduct.ProductsOffers.length > 0 ? (
                                                                                                    selectedProduct.ProductsOffers.map((item, index) => (
                                                                                                        <tr key={index}>
                                                                                                            <td>{item.MemberType}</td>
                                                                                                            <td>{item.OfferAmount}</td>
                                                                                                            <td>{item.OfferPercentage}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="3">No data available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="card mt-2">
                                                                                    <h5 className="card-header">Other Charges Table</h5>
                                                                                    <div className="table-responsive text-nowrap">
                                                                                        <table className="table">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Label Name</th>
                                                                                                    <th>Value</th>
                                                                                                    <th>isFixed</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="table-border-bottom-0">
                                                                                                {selectedProduct.OtherCharges && selectedProduct.OtherCharges.length > 0 ? (
                                                                                                    selectedProduct.OtherCharges.map((item, index) => (
                                                                                                        <tr key={index}>
                                                                                                            <td>{item.LabelName}</td>
                                                                                                            <td>{item.Value}</td>
                                                                                                            <td>{item.IsFixed ? 'Yes' : 'No'}</td>
                                                                                                        </tr>
                                                                                                    ))
                                                                                                ) : (
                                                                                                    <tr>
                                                                                                        <td colSpan="3">No data available</td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                ) : (
                                                                    <p>Loading...</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p>No data available</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <h5 className="card-header">Insurance Premium</h5>
                            <div className="table-responsive text-nowrap">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Minimum Age</th>
                                            <th>Maximum Age</th>
                                            <th>Base Premium</th>
                                            <th>GST</th>
                                            <th>Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                        {profile[0].InsurancePremiums && profile[0].InsurancePremiums.length > 0 ? (
                                            profile[0].InsurancePremiums.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.MinimumAge}</td>
                                                    <td>{item.MaximumAge}</td>
                                                    <td>{item.BasePremium}</td>
                                                    <td>{item.GST}</td>
                                                    <td>{item.TotalAmount}</td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="3">No data available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {profile[0].ProductsOffers && profile[0].ProductsOffers.length > 0 && (
                            <div className="card mt-2">
                                <h5 className="card-header">Product Offers</h5>
                                <div className="table-responsive text-nowrap">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Member Type</th>
                                                <th>Offer Amount</th>
                                                <th>Offer Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {profile[0].ProductsOffers.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.MemberType}</td>
                                                    <td>{item.OfferAmount}</td>
                                                    <td>{item.OfferPercentage}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {otherCharges.length > 0 && (
                            <div className="card mt-2">
                                <h5 className="card-header">Other Charges</h5>
                                <div className="table-responsive text-nowrap">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Label Name</th>
                                                <th>Value</th>
                                                <th>isFixed</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-border-bottom-0">
                                            {otherCharges.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.LabelName}</td>
                                                    <td>{item.Value}</td>
                                                    <td>{item.IsFixed ? 'Yes' : 'No'}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="d-flex demo-inline-spacing my-2 mx-2 align-items-center">
                <div className="card-action-title">
                    <button onClick={handleBackToList} className="btn btn-md btn-primary">Back To List</button>
                </div>
                <button onClick={handleEditForm} className="btn btn-md btn-primary">Edit</button>
            </div>
        </>
    );
}
