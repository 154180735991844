import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchData, fetchAllData } from "../../helpers/externapi";
import CommonTables from "../../Commoncomponents/CommonTables";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import moment from 'moment';
import Modal from 'react-modal';
import { TableSkeletonLoading } from "../../Commoncomponents/SkeletonLoading";
import { formatDate } from "../../Commoncomponents/CommonComponents";
import DescriptionCell from "../../Commoncomponents/DescriptionCell";

export default function PreviousList(district) {
    const [loading, setLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [consultationList, setConsultationList] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [toogleFeedback, setToggleFeedback] = useState('Customer');
    const [customerFeedback, setCustomerFeedback] = useState('');
    const [hospitalFeedback, setHospitalFeedback] = useState('');
    const [bookingConsultationId, setBookingConsultationId] = useState();
    const [successHosFeedback, setSuccessHosFeedback] = useState('');
    const [successCusFeedback, setSuccessCusFeedback] = useState('');
    const [submitFeedbackLoading, setSubmitFeedbackLoading] = useState(false);
    const [paidAmount, setPaidAmount] = useState();
    const [totalAmount, setTotalAmount] = useState();

    let UserId = localStorage.getItem("UserId");

    const tableHeads = ['Full Name', 'Mobile Number', 'Appointment Date', 'Hospital Name', 'Appointment', "Customer Feedback", "Hospital Feedback", "Feedback"]

    const tableElements = consultationList && consultationList.length > 0 ?
        consultationList.map(data => ([
            <Link
                to={data.MemberTypeId === 1 ? `/distributor/details/${data.MemberId}` : `/customers/details/${data.MemberId}`}
            >
                {data.Name}
            </Link>,
            <p>{data.MobileNumber}</p>,
            <p>{formatDate(data.AppointmentDate).slice(0, 11)}</p>,
            <a href={`/hospitals/details/${data.HospitalId}`} target="_blank">{data.HospitalName}</a>,
            <p>{data.Appointment}</p>,
            <DescriptionCell description={data.CustomerFeedBack} />,
            <DescriptionCell description={data.HospitalFeedBack} />,
            <div className="align-items-stretch">
                <button
                    className="btn btn-primary btn-sm mb-1 w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#customerFeedbackModal"
                    onClick={() => openFeedbackForm('Customer', data)}
                >
                    Customer
                </button>

                <button
                    className="btn btn-success btn-sm w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#hospitalFeedbackModal"
                    onClick={() => openFeedbackForm('Hospital', data)}
                >
                    Hospital
                </button>
            </div>
        ])) : [];

    const openFeedbackForm = (feedbackType, data) => {
        if (feedbackType === 'Customer') {
            setToggleFeedback('Customer');
            setCustomerFeedback(data.CustomerFeedBack === null ? '' : data.CustomerFeedBack);
            setPaidAmount(data.PaidAmount || 0);
            setTotalAmount(data.TotalAmount || 0);
            setSuccessCusFeedback('');
        } else if (feedbackType === 'Hospital') {
            setToggleFeedback('Hospital');
            setHospitalFeedback(data.HospitalFeedBack === null ? '' : data.HospitalFeedBack);
            setSuccessHosFeedback('');
        }
        setBookingConsultationId(data.BookingConsultationId);
    };

    const getConsultationList = async () => {
        try {
            setLoading(true);

            const skip = (currentPage - 1) * perPage;
            const take = perPage;

            const response = await fetchData('BookingConsultation/BookingConsultationListBasedonCurrentDate', { skip, take });
            const responseCount = await fetchData('BookingConsultation/BookingConsultationListBasedonCurrentDate', { skip: 0, take: 0 });

            setConsultationList(response);
            setIsDataLoaded(true);
            setTotalCount(responseCount.length);
        } catch (e) {
            console.error('Error fetching Concultation list: ', e);
        } finally {
            setLoading(false);
        }
    };

    const getConsultationListByHospital = async () => {
        try {
            setLoading(true);
            const skip = (currentPage - 1) * perPage;
            const take = perPage;

            const responspeByHos = await fetchData("BookingConsultation/GetDataByHospitalLocation", {
                skip, take,
                filter: [{ key: "DistrictId", value: district.district, operator: "IN" }]
            });

            const responspeByHosCount = await fetchData("BookingConsultation/GetDataByHospitalLocation", {
                skip: 0, take: 0,
                filter: [{ key: "DistrictId", value: district.district, operator: "IN" }]
            });

            setConsultationList(responspeByHos);
            setIsDataLoaded(true);
            setTotalCount(responspeByHosCount.length);
        } catch (e) {
            console.error("Error -BookingConsultation/GetDataByHospitalLocation: ", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (district.district.length > 0) {
            getConsultationListByHospital();
        } else {
            getConsultationList();
        }        
    }, [currentPage, perPage, district]);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const onSubmitFeedback = async (e, feedbackType) => {
        e.preventDefault();
        try {
            setSubmitFeedbackLoading(true);
            const feedbackData =
                feedbackType === 'Customer'
                    ? { customerfeedback: customerFeedback, bookingconsultationid: bookingConsultationId, UserId, PaidAmount: paidAmount, TotalAmount: totalAmount }
                    : { hospitalfeedback: hospitalFeedback, bookingconsultationid: bookingConsultationId, UserId };

            const endpoint =
                feedbackType === 'Customer'
                    ? 'BookingConsultation/UpdateCustomerFeedBack'
                    : 'BookingConsultation/UpdateHospitalFeedBack';

            const resFeedback = await fetchData(endpoint, feedbackData);

            if (resFeedback && resFeedback.status) {
                setSnackbarOpen(true);
                feedbackType === 'Customer'
                    ? setSnackbarMessage(resFeedback.message)
                    : setSnackbarMessage(resFeedback.message);

                // Reload the page after 3 seconds if the feedback is successfully submitted
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            }
        } catch (e) {
            console.error(`Error submitting ${feedbackType} feedback:`, e);
        } finally {
            setSubmitFeedbackLoading(false);
        }
    };

    const handleResetFeedback = () => {
        if (toogleFeedback === 'Customer') {
            setCustomerFeedback('');
        } else {
            setHospitalFeedback('');
        }
    };

    return (
        <>
            {loading ? <TableSkeletonLoading /> : !isDataLoaded ? <TableSkeletonLoading /> : (
                <div className='card'>
                    {consultationList && consultationList.length > 0 ? (
                        <CommonTables
                            tableHeads={tableHeads}
                            tableData={tableElements}
                            perPage={perPage}
                            currentPage={currentPage}
                            perPageChange={handlePerPageChange}
                            pageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    ) : (
                        <h4 className="text-danger text-center m-3">No records exists</h4>
                    )}

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="success">
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </div>
            )}

            {/* Customer Feedback Modal */}
            <div
                className="modal fade"
                id="customerFeedbackModal"
                tabIndex="-1"
                aria-hidden="true"
                aria-labelledby="customerFeedbackModalLabel"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="customerFeedbackModalLabel">
                                Customer Feedback
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <textarea
                                style={{ height: '100px' }}
                                className="mb-1 w-100"
                                placeholder="Enter Customer Feedback"
                                value={customerFeedback}
                                onChange={(e) => setCustomerFeedback(e.target.value)}
                            />
                            <div className="row mb-3">
                                <div className="col-12 col-sm-6 d-flex flex-column">
                                    <label className="text-dark" htmlFor="paidAmount">Enter Paid Amount</label>
                                    <input type="number" id="paidAmount" value={paidAmount}
                                        onChange={(e) => setPaidAmount(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (["e", "E", "+", "-"].includes(e.key)) e.preventDefault();
                                        }} 
                                    />
                                </div>

                                <div className="col-12 col-sm-6 d-flex flex-column">
                                    <label className="text-dark" htmlFor="totalAmount">Enter Total Amount</label>
                                    <input type="number" id="totalAmount" value={totalAmount}
                                        onChange={(e) => setTotalAmount(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (["e", "E", "+", "-"].includes(e.key)) e.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row align-items-center">
                                <button
                                    className="btn btn-primary align-self-start me-2"
                                    onClick={(e) => onSubmitFeedback(e, 'Customer')}
                                    style={{ minWidth: '100px', minHeight: '38px' }}
                                >
                                    {submitFeedbackLoading ? (
                                        <div className="spinner-border text-white" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    ) : 'Submit'}
                                </button>
                                <button
                                    className="btn btn-secondary align-self-start me-2"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-danger align-self-start"
                                    onClick={handleResetFeedback}
                                >
                                    Reset
                                </button>
                            </div>
                            {successCusFeedback && (
                                <span className="text-success mt-2 text-center">{successCusFeedback}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Hospital Feedback Modal */}
            <div
                className="modal fade"
                id="hospitalFeedbackModal"
                tabIndex="-1"
                aria-hidden="true"
                aria-labelledby="hospitalFeedbackModalLabel"
            >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="hospitalFeedbackModalLabel">
                                Hospital Feedback
                            </h4>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex flex-column">

                                <textarea
                                    style={{ height: '100px', width: '700px' }}
                                    className="mb-3"
                                    value={hospitalFeedback}
                                    onChange={(e) => setHospitalFeedback(e.target.value)}
                                />

                                <div className="d-flex flex-row align-items-center">
                                    <button
                                        className="btn btn-primary align-self-start me-2"
                                        onClick={(e) => onSubmitFeedback(e, 'Hospital')}
                                        style={{ minWidth: '100px', minHeight: '38px' }}
                                    >
                                        {submitFeedbackLoading ? (
                                            <div className="spinner-border text-white" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        ) : 'Submit'}
                                    </button>
                                    <button
                                        className="btn btn-secondary align-self-start me-2"
                                        type="button"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-danger align-self-start"
                                        onClick={handleResetFeedback}
                                    >
                                        Reset
                                    </button>
                                </div>
                                {successHosFeedback && (
                                    <span className="text-success mt-2 text-center">{successHosFeedback}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}