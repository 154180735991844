import React, { useState } from "react";
import { fetchAllData } from "../../helpers/externapi";
import {
    Container, Box, TextField, Button, CircularProgress,
    Typography, Card, CardContent, CardHeader, Alert, Grid
} from '@mui/material';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function PaymentDetailsList(props) {
    const [inputValue, setInputValue] = useState("");
    const [showCard, setShowCard] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = async () => {
        setShowCard(true);
        setLoading(true);
        setError(false);
        try {
            const cardList = await fetchAllData(`PaymentDetails/GetPaymentDetailsByUTRNumber/${inputValue}`);

            if (cardList && cardList.length > 0) {
                setPaymentDetails(cardList[0]);
            } else {
                setPaymentDetails(null);
                setError(true);
            }
        } catch (error) {
            console.error("Error fetching payment details:", error);
            setPaymentDetails(null);
            setError(true);
        }
        setLoading(false);
    };

    const handleDownload = () => {
        const input = document.getElementById("downloadable-content");
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF({
                orientation: "landscape",
                unit: "px",
                format: [canvas.width, canvas.height]
            });
            pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
            pdf.save(`${paymentDetails.Name}.pdf`);
        });
    };

    const handlePrint = () => {
        const content = document.getElementById("downloadable-content").innerHTML;
        const printWindow = window.open("", "", "width=800,height=600");
        printWindow.document.write('<html><head><title>Print Payment Details</title>');
        printWindow.document.write('</head><body >');
        printWindow.document.write(content);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <Card elevation={3}>
            <CardHeader
                title="Payment Details"
                sx={{ textAlign: 'center', bgcolor: '#1976d2', color: 'white' }}
            />
            <CardContent>
                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Enter UTR Number"
                        value={inputValue}
                        onChange={handleInputChange}
                        inputProps={{ maxLength: 15 }}
                        style={{ marginRight: '10px' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{ height: '56px' }}
                    >
                        Search
                    </Button>
                </Box>
                {showCard && (
                    <Box mt={4} position="relative">
                        {loading && (
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="200px"
                            >
                                <CircularProgress />
                            </Box>
                        )}

                        {!loading && error && (
                            <Alert severity="error" style={{ marginTop: '20px', textAlign: 'center' }}>
                                No payment details available for the provided UTR Number.
                            </Alert>
                        )}
                    </Box>
                )}
                {!loading && paymentDetails && (
                    <div>
                        <div className="row invoice-preview" >
                            <div className="col-xl-9 col-md-8 col-12 mb-md-0 mb-4" id="downloadable-content">
                                <div className="card invoice-preview-card">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between flex-xl-row flex-md-column flex-sm-row flex-column p-sm-3 p-0">
                                            <div className="mb-xl-0 mb-4">
                                                <div className="d-flex svg-illustration mb-3 gap-2">
                                                    <span className="app-brand-logo demo">
                                                        <img src="/assets/applogo.png" height="50" alt="App Logo" />
                                                    </span>
                                                    <span className="app-brand-text demo text-body fw-bolder">OHOINDIA</span>
                                                </div>
                                                <p className="mb-1">5th floor,1-98/9/4/20, Arunodaya colony,,</p>
                                                <p className="mb-1">VIP Hills, Silicon Valley, Madhapur,</p>
                                                <p className="mb-1">HITEC City, Hyderabad, Telangana 500081.</p>
                                                <p className="mb-0">+91 70321 07108</p>
                                            </div>
                                            <div>

                                                <div className="mb-2">
                                                    <span className="me-1">Date :</span>
                                                    <span className="fw-semibold">{new Date(paymentDetails.PaidDate).toLocaleString()}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="card-body">
                                        <div className="row p-sm-3 p-0">
                                            <div className="col-xl-6 col-md-12 col-sm-5 col-12 mb-xl-0 mb-md-4 mb-sm-0 mb-4">
                                                <h6 className="pb-2">Payment Details :</h6>
                                                <p className="mb-1">FullName: {paymentDetails.Name}</p>

                                                <p className="mb-1">Mobile Number: {paymentDetails.MobileNumber}</p>
                                                <p className="mb-0">Gender: {paymentDetails.Gender}</p>
                                                <p className="mb-1">Age: {paymentDetails.Age}</p>
                                            </div>
                                            <div className="col-xl-6 col-md-12 col-sm-7 col-12">
                                                <h6 className="pb-2">Bill To:</h6>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td className="pe-3">Total Amount:</td>
                                                            <td>₹{paymentDetails.PaidAmount}</td>
                                                        </tr>
                                                        {/*<tr>
                                                            <td className="pe-3">Bank name:</td>
                                                            <td>Sbi Bank</td>
                                                        </tr>*/}
                                                        <tr>
                                                            <td className="pe-3">Country:</td>
                                                            <td>India</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="pe-3">UTR Number:</td>
                                                            <td>{paymentDetails.UTRNumber}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table border-top m-0">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Type of card</th>
                                                    <th>Transaction Type</th>
                                                    <th>Cash Taken By</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="text-nowrap">{paymentDetails.ProductName}</td>
                                                    <td>{paymentDetails.TypeofCard}</td>
                                                    <td>{paymentDetails.TypeofTransaction}</td>
                                                    <td>{paymentDetails.CashTakenBy}</td>
                                                    <td>₹{paymentDetails.PaidAmount}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <span className="fw-semibold">Note:</span>
                                                <span>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance
                                                    projects. Thank You!</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-4 col-12 invoice-actions">
                                <div className="card">
                                    <div className="card-body">
                                        <button className="btn btn-label-secondary d-grid w-100 mb-3" onClick={handleDownload}>
                                            Download
                                        </button>
                                        <button className="btn btn-label-secondary d-grid w-100 mb-3" onClick={handlePrint}>
                                            Print
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </CardContent>
        </Card>
    );
}
