import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { fetchData } from "../../helpers/externapi";
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CommonTables from '../../Commoncomponents/CommonTables'
import { TableSkeletonLoading } from "../../Commoncomponents/SkeletonLoading";
import DescriptionCell from "../../Commoncomponents/DescriptionCell";
import { isAction } from "@reduxjs/toolkit";

export default function List(props) {
    const [loading, setLoading] = useState(true);
    const [productsData, setProductsData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [filterCriteria, setFilterCriteria] = useState([]);
    const [isNavigating, setIsNavigating] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // const [activeProducts, setActiveProducts] = useState([]);
    // const [inActiveProducts, setInActiveProducts] = useState([]);

    const navigate = useNavigate();
    const location = useLocation();

    const tableHeads = ["Product Name", "Short Description", "Is Combo", "Service Provider", "Base Price", "GST", "Total Amount"];

    // const tableElementsActive = activeProducts.length > 0 ?
    //     activeProducts.map(data => ([
    //         <Link
    //             to={`/products/details/${data.ProductsId}`}
    //             className="text-start-important"
    //             style={{
    //                 whiteSpace: 'normal',
    //                 textAlign: 'start',
    //                 display: 'block',
    //             }}
    //             onClick={() => handleNavigation(`/products/details/${data.ProductsId}`)}
    //         >
    //             {data.ProductName}
    //         </Link>,
    //         <DescriptionCell description={data.ShortDescription} />,
    //         /*<div dangerouslySetInnerHTML={{ __html: data.KeyFeatures }} />*/
    //         data.IsCombo ? (
    //             <p className='badge bg-label-success'>COMBO</p>
    //         ) : (
    //             <p className='badge bg-label-warning'>INDIVIDUAL</p>
    //         ),
    //         data.ServiceProvider,
    //         data.BasePremium,
    //         data.GST,
    //         data.InsurancePremiums[0].TotalAmount,
    //     ])) : [];

    const tableElements = productsData.length > 0 ?
        productsData.map(data => ([
            <Link
                to={`/products/details/${data.ProductsId}`}
                className="text-start-important"
                style={{
                    whiteSpace: 'normal',
                    textAlign: 'start',
                    display: 'block',
                }}
                onClick={() => handleNavigation(`/products/details/${data.ProductsId}`)}
            >
                {data.ProductName}
            </Link>,
            <DescriptionCell description={data.ShortDescription} />,
            /*<div dangerouslySetInnerHTML={{ __html: data.KeyFeatures }} />*/
            data.IsCombo ? (
                <p className='badge bg-label-success'>COMBO</p>
            ) : (
                <p className='badge bg-label-warning'>INDIVIDUAL</p>
            ),
            data.ServiceProvider,
            data.BasePremium,
            data.GST,
            data.InsurancePremiums[0].TotalAmount,
        ])) : [];

    // const tableElementsInActive = inActiveProducts.length > 0 ?
    //     inActiveProducts.map(data => ([
    //         <Link
    //             to={`/products/details/${data.ProductsId}`}
    //             className="text-start-important"
    //             style={{
    //                 whiteSpace: 'normal',
    //                 textAlign: 'start',
    //                 display: 'block',
    //             }}
    //             onClick={() => handleNavigation(`/products/details/${data.ProductsId}`)}
    //         >
    //             {data.ProductName}
    //         </Link>,
    //         <DescriptionCell description={data.ShortDescription} />,
    //         /*<div dangerouslySetInnerHTML={{ __html: data.KeyFeatures }} />*/
    //         data.IsCombo ? (
    //             <p className='badge bg-label-success'>COMBO</p>
    //         ) : (
    //             <p className='badge bg-label-warning'>INDIVIDUAL</p>
    //         ),
    //         data.ServiceProvider,
    //         data.BasePremium,
    //         data.GST,
    //         data.InsurancePremiums[0].TotalAmount,
    //     ])) : [];

    const getProductcountData = async () => {
        setLoading(true);
        const productCountData = await fetchData(`CommonRowCount/GetTableRowCount`, 
            { tableName: "Products", isActive: location.pathname.includes('inactive') ? false : true });
        const totalCount = productCountData[0]?.CountOfRecords || 0;
        setTotalCount(totalCount);
        setLoading(false);
    };

    useEffect(() => {
        getProductcountData();
    }, [location]);

    const getProductData = async () => {
        setLoading(true);
        try {
            const skip = (currentPage - 1) * perPage;
            const take = perPage;
            let dataToDisplay = [];

            if (filterCriteria.length > 0) {
                const filterData = await fetchData("Products/GetMultipleDataByFilter", {
                    skip: 0,
                    take: -1,
                    filter: filterCriteria
                });
                setTotalCount(filterData.length);
                const startIndex = (currentPage - 1) * perPage;
                const paginatedFilterData = filterData.slice(startIndex, startIndex + perPage);
                dataToDisplay = paginatedFilterData.map(member => ({ ...member }));
            } else {
                const productData = await fetchData("Products/fetchProductsList/all", { skip, take, 
                    isActive: location.pathname.includes('inactive') ? false : true });

                dataToDisplay = productData.map(product => ({ ...product }));
            }

            const insurancePremiumData = await fetchData("InsurancePremium/all", { skip: 0, take: -1 });

            let totalProductData = dataToDisplay.map(product => {
                const TotalAmountmatchingPremium = insurancePremiumData.find(premium => premium.ProductsId === product.ProductsId);
                return {
                    ...product,
                    BasePremium: TotalAmountmatchingPremium ? TotalAmountmatchingPremium.BasePremium : null,
                    GST: TotalAmountmatchingPremium ? TotalAmountmatchingPremium.GST : null,
                    TotalAmount: TotalAmountmatchingPremium ? TotalAmountmatchingPremium.TotalAmount : null
                };
            });

            // const active = totalProductData.filter(data => data.IsActive);
            // const inActive = totalProductData.filter(data => !data.IsActive || data.IsActive === null);

            setProductsData(totalProductData);
            // setActiveProducts(active);
            // setInActiveProducts(inActive);
            setIsDataLoaded(true);
        } catch (error) {
            console.error("Error fetching product data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getProductData();
    }, [currentPage, perPage, filterCriteria, location]);

    useEffect(() => {
        setLoading(props.loading);
        setLoading(props.error);
    }, [props.loading, props.error]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const handleNavigation = (path) => {
        setIsNavigating(true);
        navigate(path);
        setIsNavigating(false);
    };

    return (
        <>
            {loading ? <TableSkeletonLoading /> : !isDataLoaded ? <TableSkeletonLoading /> : (
                <>
                    {(loading || isNavigating) && (
                        <div style={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: 1000,
                        }}>
                            <CircularProgress />
                        </div>
                    )}
                    <Card style={{ margin: "5px", borderRadius: "10px" }}>
                        <CardContent>
                            <div style={{ overflowX: "auto" }}>
                                <CommonTables
                                    tableHeads={tableHeads}
                                    //tableData={location.pathname.includes('inactive') ? tableElementsInActive : tableElementsActive}
                                    tableData={tableElements}
                                    perPage={perPage}
                                    currentPage={currentPage}
                                    perPageChange={handlePerPageChange}
                                    pageChange={handlePageChange}
                                    totalCount={totalCount}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </>
            )}
        </>
    );
}
