import React, { useEffect, useState } from "react";
import { fetchData } from "../../helpers/externapi";
import MapBoxHospital from "../../Components/MapBoxHospital";

export default function HospitalPersonalDetails(props) {
    const profile = props.data;
    const [mocUrl, setMocUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        const getMocUrl = async () => {
            const response = await fetchData('ConfigValues/all', { skip: 0, take: 0 });
            const bucketUrl = response && response.length > 0 && response.find(val => val.ConfigKey === "mouBucketURL");
            const imageUrl = response && response.length > 0 && response.find(val => val.ConfigKey === "hospitalImagesURL");
            setMocUrl(bucketUrl.ConfigValue);
            setImageUrl(imageUrl.ConfigValue);
        };

        getMocUrl();
    }, []);

    const handleUpdateLocation = async () => {
        try {
            const response = await fetchData('Geocoding/forward', { hospitalId: profile.HospitalId });
            window.location.reload();
        } catch (error) {
            console.error("Error fetching location data: ", error);
        }
    };

    return (
        <>
            <div className="row mt-3 align-items-stretch">
                <div className="col-12 col-md-8 mb-3">
                    <div className="card p-2 p-md-3 h-100 d-flex flex-column">
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            {profile.Image ?
                                <img src={`${imageUrl}${profile.Image}`} alt=''
                                    style={{ height: '100px', width: '120px', borderRadius: '10px' }}
                                />
                                : <img src={`${process.env.PUBLIC_URL}/assets/hospitaldummyimage.jpg`} alt=''
                                    style={{ height: '100px', width: '120px' }} />
                            }

                            <div className="d-flex flex-column ms-1 ms-sm-3 ms-md-4">                                
                                <div className="d-flex flex-row align-items-center">
                                    <i className="fas fa-phone me-2 text-dark"></i>
                                    {profile.HospitalMobileNumber && profile.HospitalMobileNumber.length > 0 ? (
                                        <a href={`tel:${profile.HospitalMobileNumber}`}>{profile.HospitalMobileNumber}</a>
                                    ) : <span className="text-danger">Not exist</span>
                                    }
                                </div>

                                <div className="d-flex flex-row align-items-center">
                                    <i class="fa-solid fa-blender-phone me-2 text-dark"></i>
                                    {profile.Landline && profile.Landline.length > 0 ? (
                                        <a href={`tel:${profile.Landline}`}>{profile.Landline}</a>
                                    ) : <span className="text-danger">Not exist</span>
                                    }
                                </div>

                                <div className="d-flex flex-row flex-wrap align-items-center">
                                    <i className="fas fa-envelope me-2 text-dark"></i>
                                    {profile.Email && profile.Email.length > 0 ? (
                                        <a href={`mailto:${profile.Email}`}>{profile.Email}</a>
                                    ) : <span className="text-danger">Not exist</span>
                                    }
                                </div>
                            </div>
                        </div>

                        <h5 className="text-dark fw-semibold my-3 fs-4">{profile.HospitalName}</h5>

                        <div className="d-flex flex-row align-items-center flex-wrap">
                            {profile.City && profile.City.length > 0 && (
                                <div className="d-flex flex-row align-items-center me-4 me-md-5 mb-2">
                                    <i class="fa-solid fa-location-dot me-2 text-dark"></i> {profile.City}
                                </div>
                            )}

                            {profile.Pincode && (
                                <div className="d-flex flex-row align-items-center me-4 me-md-5 mb-2">
                                    <i className="fas fa-thumbtack me-2 text-dark"></i> {profile.Pincode}
                                </div>
                            )}

                            {profile.Website && profile.Website.length > 0 && (
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <i className="fas fa-globe me-2 text-dark"></i>
                                    <a href={profile.Website} target="_blank" rel="noopener noreferrer">
                                        Hospital Website
                                    </a>
                                </div>
                            )}
                        </div>

                        <div className="d-flex flex-row align-items-center flex-wrap">
                            {profile.Mandal && profile.Mandal.length > 0 && (
                                <div className="d-flex flex-row align-items-center me-4 me-md-5 mb-2">
                                    <strong className="me-1 text-dark">Mandal:</strong> {profile.Mandal}
                                </div>
                            )}
                            {profile.HospitalCode && profile.HospitalCode.length > 0 && (
                                <div className="d-flex flex-row align-items-center me-4 me-md-5 mb-2">
                                    <strong className="me-1 text-dark">Code:</strong> {profile.HospitalCode}
                                </div>
                            )}
                            {profile.Specialization && profile.Specialization.length > 0 && (
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <strong className="me-1 text-dark">Specialization:</strong> {profile.Specialization}
                                </div>
                            )}
                        </div>

                        {profile.AddressLine1 && profile.AddressLine1.length > 0 && (
                            <div className="d-flex flex-row mb-2">
                                <strong className="me-1 text-dark">Address:</strong> {profile.AddressLine1}
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-12 col-md-4 mb-3">
                    <div className="card h-100 d-flex flex-column">
                        {profile.Latitude && profile.Longitude && profile.Latitude.length > 0 && profile.Longitude.length > 0 ? (

                            <MapBoxHospital hospitalsData={[profile]} />

                        ) : (
                            <>
                                <h5 className="ps-2 ps-md-3 pt-2 pt-md-3 m-0 fw-semibold" style={{ color: '#008000' }}>
                                    Location Coordinates </h5>
                                <hr className="mb-0" />
                                <span className="text-danger text-center mt-md-5">Please update location Coordinates</span>
                                <div className="align-self-center">
                                    <button className="btn btn-primary" onClick={() => handleUpdateLocation()}>Update Location</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                <div className="col-12 col-md-6 mb-3">
                    <div className="card h-100 d-flex flex-column">
                        <h5 className="m-0 ps-3 pt-3" style={{ color: '#008000' }}>SPOCs INFORMATION </h5>
                        <hr className="mb-0" />

                        <div className="row p-3 h-100 align-items-stretch">
                            <div className="col-12 mb-3 mb-md-2">
                                <div className="d-flex flex-column justify-content-center align-items-start border border-info rounded p-2 h-100">
                                    {profile.Spoc1Name && profile.Spoc1Name.length > 0 ? (
                                        <>
                                            <div className="d-flex flex-row">
                                                <h5 className="m-0 me-2 text-dark">{profile.Spoc1Name}</h5>
                                                <span className="badge bg-label-success">Primary</span>
                                            </div>
                                            {profile.Spoc1Designation && profile.Spoc1Designation.length > 0 && (
                                                <span>({profile.Spoc1Designation})</span>
                                            )}
                                            <p className="m-0">
                                                <span className="text-dark me-2">Primary Contact:</span>
                                                {profile.Spoc1ContactNumber && profile.Spoc1ContactNumber.length > 0 ?
                                                    profile.Spoc1ContactNumber : (
                                                        <span className="text-danger">Not exist</span>
                                                    )}
                                            </p>
                                            <p className="m-0">
                                                <span className="text-dark me-2">Secondary Contact:</span>
                                                {profile.Spoc1AlternateContactNumber && profile.Spoc1AlternateContactNumber.length > 0 ?
                                                    profile.Spoc1AlternateContactNumber : (
                                                        <span className="text-danger">Not exist</span>
                                                    )}
                                            </p>
                                        </>
                                    ) : <span className="text-danger align-self-center text-center">Primary Spoc details not exist</span>}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex flex-column justify-content-center align-items-start border border-info rounded p-2 h-100">
                                    {profile.Spoc2Name && profile.Spoc2Name.length > 0 ? (
                                        <>
                                            <div className="d-flex flex-row">
                                                <h5 className="m-0 me-2 text-dark">{profile.Spoc2Name}</h5>
                                                <span className="badge bg-label-warning">Secondary</span>
                                            </div>
                                            {profile.Spoc2Designation && profile.Spoc2Designation.length > 0 && (
                                                <span>({profile.Spoc2Designation})</span>
                                            )}
                                            <p className="m-0">
                                                <span className="text-dark me-2">Primary Contact:</span>
                                                {profile.Spoc2ContactNumber && profile.Spoc2ContactNumber.length > 0 ?
                                                    profile.Spoc2ContactNumber : (
                                                        <span className="text-danger">Not exist</span>
                                                    )}
                                            </p>
                                            <p className="m-0">
                                                <span className="text-dark me-2">Secondary Contact:</span>
                                                {profile.Spoc2AlternateContactNumber && profile.Spoc2AlternateContactNumber.length > 0 ?
                                                    profile.Spoc2AlternateContactNumber : (
                                                        <span className="text-danger">Not exist</span>
                                                    )}
                                            </p>
                                        </>
                                    ) : <span className="text-danger align-self-center text-center">
                                        Secondary Spoc details not exist</span>}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <div className="card mb-2">
                        {/* <div className="card-header">
                            <h5 className="card-title" style={{ color: '#008000' }}>ADDITIONAL INFORMATION</h5>
                        </div> */}
                        <h5 className="m-0 ps-3 pt-3" style={{ color: '#008000' }}>ADDITIONAL INFORMATION</h5>
                        <hr className="mb-0" />

                        <div className="d-flex flex-row align-items-center flex-wrap p-3">
                            <p className="me-3 me-sm-5">
                                <strong className="text-dark me-1">Aarogyasri:</strong>
                                {profile.Aarogyasri || <span className="text-danger">Not given</span>}
                            </p>
                            <p className="me-3 me-sm-5">
                                <strong className="text-dark me-1">Call to Front Desk:</strong>
                                {profile.CallToFrontDesk || <span className="text-danger">Not given</span>}
                            </p>
                            <p className="me-3 me-sm-5">
                                <strong className="text-dark me-1">Resident Counselling Fee:</strong>
                                {profile.PatientCounsellingFee || <span className="text-danger">Not given</span>}
                            </p>
                            <p className="me-3 me-sm-5">
                                <strong className="text-dark me-1">Menu Card for Diagnostics:</strong>
                                {profile.MenuCardForDiagnostics || <span className="text-danger">Not given</span>}
                            </p>
                            <p className="me-3 me-sm-5">
                                <strong className="text-dark me-1">Discount On Diagnostics:</strong>
                                {profile.DiscountOnDiagnostics || <span className="text-danger">Not given</span>}
                            </p>
                            <p className="me-3 me-sm-5">
                                <strong className="text-dark me-1">Is Free OP Consultation:</strong>
                                {profile.IsFreeOPConsultation || <span className="text-danger">Not given</span>}
                            </p>
                            <p className="me-3 me-sm-5">
                                <strong className="text-dark me-1">Is Agreement Received:</strong>
                                {profile.IsAgreementReceived ? 'Yes' : <span className="text-danger">Not Received</span>}
                            </p>
                            <p className="me-3 me-sm-5">
                                <strong className="text-dark me-1">MOU File:</strong>
                                {profile.MOUFileName ? (
                                    <a
                                        className="btn btn-md btn-primary"
                                        href={`${mocUrl}${profile.MOUFileName}`}
                                        target="_blank" download
                                        style={{
                                            color: "white", textDecoration: "none", padding: "5px 10px",
                                            borderRadius: "4px", fontSize: "14px", cursor: "pointer", marginLeft: 5
                                        }}
                                    >
                                        Download
                                    </a>
                                ) : (
                                    <span className="text-danger">Not given</span>
                                )}
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}