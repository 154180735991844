import React, { useEffect, useState } from 'react';
import Cleave from 'cleave.js/react';
import "cleave.js/dist/addons/cleave-phone.in";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchAllData, fetchData, fetchUpdateData } from "../../helpers/externapi";
import Alert from '@mui/material/Alert';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import Snackbar from '@mui/material/Snackbar';

export default function Registration() {
    const location = useLocation();
    const profileFromLocation = location.state ? location.state.profile : null;
    const [loading, setLoading] = React.useState(true);
    const [states, setStates] = React.useState([]);
    const [districts, setDistricts] = React.useState([]);
    const [events, setEvent] = React.useState();
    const [membertype, setMemberType] = React.useState();
    const [routename, setRouteName] = React.useState();
    const [rmName, setRMName] = React.useState();
    const [membersData, setMembersData] = React.useState();
    const [isEditForm, setIsEditForm] = useState(true);
    const [isAddForm, setIsAddForm] = useState(false);
    const [memberId, setMemberId] = React.useState('');
    const navigate = useNavigate();
    const [formError, setFormError] = useState({});
    const [selectedStateId, setSelectedStateId] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isDistrictDisabled, setIsDistrictDisabled] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const initialFormData = {
        Name: "", Email: "", MobileNumber: "", AlternateMobileNumber: "", Gender: "", DateofBirth: "", "MobileOTP": "",
        EmailOTP: "", AddressLine1: "", AddressLine2: "", Medals: "", DistrictId: "", EventId: "", StateId: "", City: "", Pincode: "",
        Age: "", Village: "", Mandal: "", MemberTypeId: "", RMId: "", RouteMaps: "", TelecallerName: "", RouteName: "", RouteMapId: ""
    }
    const [formData, setFormData] = React.useState(initialFormData);

    let UserId = localStorage.getItem("UserId");    

    useEffect(() => {
        if (profileFromLocation) {
            setIsEditForm(true);
            setMemberId(profileFromLocation.MemberId);
            setFormData(profileFromLocation);
            setSelectedStateId(profileFromLocation.StateId);
        } else {
            setIsAddForm(true);
            setFormData(initialFormData);
        }
    }, [profileFromLocation]);

    useEffect(() => {
        const getStates = async () => {
            setLoading(true);
            const statesData = await fetchData("States/all", { "skip": 0, "take": 0 });
            setStates(statesData);
            setLoading(false);
        }
        getStates();
    }, []);

    useEffect(() => {
        const getDistricts = async () => {
            if (selectedStateId !== null) {
                setLoading(true);
                const districtsData = await fetchAllData(`Districts/GetByStateId/${selectedStateId}`);
                setDistricts(districtsData.map(district => ({ ...district, id: district.DistrictId })));
                setDistricts(districtsData);
                setLoading(false);
            } else {
                setDistricts([]);
            }
        }
        getDistricts();
    }, [selectedStateId]);

    useEffect(() => {
        const getEvent = async () => {
            const eventData = await fetchData("Event/all", { "skip": 0, "take": 0 });
            setEvent(eventData);
            setLoading(false);
        };
        getEvent();

        const getMemberType = async () => {
            const membertype = await fetchData("MemberTypes/all", { "skip": 0, "take": 0 });
            const filteredMemberType = membertype.filter(type => type.MemberTypeId !== 0);
            setMemberType(filteredMemberType);
        };

        getMemberType();

        const getRouteName = async () => {
            const routename = await fetchData("RouteMap/all", { "skip": 0, "take": 0 });
            setRouteName(routename);

            setLoading(false);
        };
        getRouteName();

        const getRMName = async () => {
            const rmName = await fetchAllData("Users/GetRMNames");
            setRMName(rmName);

            setLoading(false);
        };
        getRMName();

    }, []);

    const handleBackToView = () => {
        if (!profileFromLocation) {
            // If profileFromLocation is not defined, navigate to the default route
            navigate("/distributor/list");
            return; // Exit the function
        }
        if (profileFromLocation.MemberTypeId === 1) {
            navigate(`/distributor/details/${memberId}`);
        } else if (profileFromLocation.MemberTypeId === 2) {
            navigate(`/customers/details/${memberId}`);
        } else {
            navigate("/distributor/list");
        }
    };

    const resetForm = () => {
        setFormData(initialFormData);
        setFormError({});
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const validateForm = async () => {
        let err = {};

        if (typeof formData.Name === 'string' && formData.Name.trim() === '') {
            err.Name = 'Please enter your Full Name';
        }
        if (typeof formData.Email === 'string' && formData.Email.trim() !== '') {
            let emailRegex = /^\w+([\.-]?\w+)*@gmail.com$/;
            if (!emailRegex.test(formData.Email)) {
                err.Email = 'Email must be in @gmail.com format';
            }
        }
        if (typeof formData.MobileNumber === 'string' && formData.MobileNumber.trim() === '') {
            err.MobileNumber = 'Please enter a valid 10-digit mobile number';
        } else if (!/^[6-9]\d{9}$/.test(formData.MobileNumber.trim())) {
            err.MobileNumber = 'Mobile Number must start with 6, 7, 8, or 9 and must be 10 digits';
        } else if (!profileFromLocation) {
            const isMobileNumberValid = await checkMobileNumberValidity(formData.MobileNumber);
            if (isMobileNumberValid) {
                err.MobileNumber = 'Mobile number already exists';
            }
        }

        if (typeof formData.Gender === 'string' && formData.Gender.trim() === '') {
            err.Gender = 'Please Select your Gender';
        }
        if (typeof formData.DateofBirth === 'string' && formData.DateofBirth.trim() === '' && (typeof formData.Age !== 'string' || formData.Age.trim() === '')) {
            err.DateofBirth = 'Please select your date of birth or enter your age';
        } else if (typeof formData.DateofBirth === 'string' && formData.DateofBirth.trim() === '') {
            const age = formData.Age.trim();
            if (!/^\d+$/.test(age) || parseInt(age) < 18 || parseInt(age) > 70) {
                if (!/^\d+$/.test(age)) {
                    err.DateofBirth = 'Please enter valid age ';
                } else {
                    err.DateofBirth = 'User must be between 18 to 70 years only';
                }
            }
        } else if (typeof formData.Age === 'string' && formData.Age.trim() === '') {
            const age = calculateAge(formData.DateofBirth);
            if (age < 18 || age > 70) {
                err.DateofBirth = 'User must be between 18 to 70 years only';
            }
        } else if (typeof formData.Age === 'string' && !/^\d+$/.test(formData.Age.trim())) {
            err.DateofBirth = 'Please enter valid age';
        }

        if (typeof formData.AddressLine1 === 'string' && formData.AddressLine1.trim() === '') {
            err.AddressLine1 = 'Please enter your address';
        }
        if (!profileFromLocation) {
            if (typeof formData.DistrictId === 'string' && formData.DistrictId.trim() === '') {
                err.DistrictId = 'Please select your District';
            }
        } else {
            if (!formData.DistrictId) {
                err.DistrictId = 'Please select your District';
            }
        }
        if (!profileFromLocation) {
            if (typeof formData.MemberTypeId === 'string' && formData.MemberTypeId.trim() === '') {
                err.MemberTypeId = 'Please select Member type';
            }
        } else {
            if (formData.MemberTypeId.length === 0) {
                err.MemberTypeId = 'Please select Member type';
            }
        }
        if (!profileFromLocation) {
            if (typeof formData.StateId === 'string' && formData.StateId.trim() === '') {
                err.StateId = 'Please select your State';
            }
        } else {
            if (!formData.StateId) {
                err.StateId = 'Please select your State';
            }
        }

        if (typeof formData.Pincode === 'string' && formData.Pincode.trim() === '') {
            err.Pincode = 'Please enter a valid 6-digit pin code';
        } else if (typeof formData.Pincode === 'string' && formData.Pincode.trim().length !== 6) {
            err.Pincode = 'Pincode must be 6 digits';
        }
        if (typeof formData.Village === 'string' && formData.Village.trim() === '') {
            err.Village = 'Please enter your Village';
        }
        if (typeof formData.Mandal === 'string' && formData.Mandal.trim() === '') {
            err.Mandal = 'Please enter your Mandal/City';
        }

        setFormError({ ...err });

        const isValid = Object.keys(err).length === 0;

        return isValid;
    };

    const onChangeHandler = (event) => {
        const { name, value } = event.target;
        let updatedFormData = { ...formData, [name]: value };
        let error = '';

        setFormData((prevData) => {
            const selectedRoute = routename.find(route => route.RouteMapId === value);
            return {
                ...prevData,
                [name]: value,
                RMName: selectedRoute ? selectedRoute.RMName : '',
                TelecallerName: selectedRoute ? selectedRoute.TelecallerName : ''
            };
        });

        if (name === 'Gender') {
            error = '';
        }
        if (name === 'Age') {
            const ageValue = value.trim();
            if (!isNaN(ageValue) && parseInt(ageValue) >= 18 && parseInt(ageValue) <= 70) {
                const today = new Date();
                const year = today.getFullYear() - parseInt(ageValue, 10);
                const dob = `${year}-01-01`;
                updatedFormData = { ...updatedFormData, DateofBirth: dob };
            } else {
                error = 'User must be between 18 to 70 years only';
                updatedFormData = { ...updatedFormData, DateofBirth: '' };
            }
        } else if (name === 'DateofBirth') {
            const age = calculateAge(value);
            if (age >= 18 && age <= 70) {
                updatedFormData = { ...updatedFormData, Age: age.toString() };
            } else {
                error = 'User must be between 18 to 70 years only';
                updatedFormData = { ...updatedFormData, Age: '' };
            }
        }

        setFormData(updatedFormData);
        setFormError({ ...formError, [name]: error });
        if (name === 'StateId') {
            setIsDistrictDisabled(!value);
            setSelectedStateId(value);
            updatedFormData.DistrictId = '';
        }
    };

    const calculateAge = (dob) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const month = today.getMonth() - birthDate.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const checkMobileNumberValidity = async (mobileNumber) => {
        const response = await fetchData("Member/mobileNoValid", {
            mobileNumber: mobileNumber
        });
        const data = await response;
        if (data.status === false) {
            return false;
        } else {
            return true;
        }
    };

    const onSubmitHandler = async (event) => {
        event.preventDefault();

        const isValid = await validateForm();

        if (isValid) {
            const mobileNumber = formData.MobileNumber;
            const formattedNumber = mobileNumber ? mobileNumber.replace(/\s/g, '') : '';
            let dob = formData.DateofBirth;
            let age = formData.Age ? String(formData.Age).trim() : '';

            if (age !== '') {
                const today = new Date();
                const year = today.getFullYear() - parseInt(age, 10);
                age = ``;
            } else {
                dob = '';
            }

            let MemberId;
            const submitData = {
                memberId: formData.MemberId,
                userId: UserId,
                registerOn: formData.RegisterOn,
                name: formData.Name,
                mobileNumber: formattedNumber,
                alternateMobileNumber: formData.AlternateMobileNumber,
                email: formData.Email,
                gender: formData.Gender,
                dateOfBirth: formData.DateofBirth,
                addressLine1: formData.AddressLine1,
                routemaps: formData.RouteMaps,
                routenames: formData.RouteName,
                addressLine2: formData.AddressLine2,
                stateId: formData.StateId,
                districtId: formData.DistrictId,
                pinCode: formData.Pincode,
                mandal: formData.Mandal,
                village: formData.Village,
                city: formData.City,
                password: formData.Password,
                memberTypeId: formData.MemberTypeId,
                isProfileCompleted: formData.IsProfileCompleted,
                isActive: formData.IsActive,
                approvedOn: formData.ApprovedOn,
                approvedBy: formData.approvedBy,
                pinNo: formData.PinNo,
                noofTimes: formData.NoofTimes,
                attemptUpdateOn: formData.AttemptUpdateOn,
                remarks: formData.Remarks,
                isApproved: formData.IsApproved,
                status: formData.Status,
                clinicName: formData.ClinicName,
                ohocode: formData.OHOCODE,
                age: formData.Age,
                associaionId: formData.AssociaionId,
                cardType: formData.CardType,
                joinedBy: formData.JoinedBy,
                allocatedRM: formData.RMId,
                reportingTo: formData.RMId,
            };

            if (age !== '') {
                submitData.age = parseInt(age, 10);
            }

            if (formData.EventId) {
                submitData.eventId = formData.EventId;
            }
            if (formData.MemberTypeId) {
                submitData.memberTypeId = formData.MemberTypeId
            }
            // if (formData.RouteMapId) {
            //     submitData.routemapid = formData.RouteMapId
            // }

            // if (formData.RMName) {
            //     submitData.RMName = formData.RMName
            // }

            let response;
            try {

                if (isAddForm) {
                    response = await fetchData("Member/add", submitData);
                    if (response.memberTypeId === 16) {
                        setSnackbarMessage("Distributor Added Successfully")
                    }
                    else {
                        setSnackbarMessage("Member Added Successfully")
                    }
                } else {
                    response = await fetchUpdateData("Member/update", submitData);

                    if (response.memberTypeId === 16) {
                        setSnackbarMessage("Distributor updated Successfully")
                    }
                    else {
                        setSnackbarMessage("Member updated Successfully");
                    }
                }

                setMembersData(response);
                setLoading(false);

                if (!profileFromLocation) {
                    MemberId = response.memberId;
                } else {
                    MemberId = response.memberId;
                }
            } catch (error) {
                console.error('Error submitting form:', error);
            }

            setIsSubmitted(true);
            setTimeout(() => {
                setIsSubmitted(false);
                if (response.memberTypeId === 2) {
                    navigate(`/customers/details/${MemberId}`);
                } else {
                    navigate(`/distributor/details/${MemberId}`);
                }
            }, 4000);
            setSnackbarOpen(true);
        }
    };

    return (
        <>
            <div className="App">
                <form onSubmit={onSubmitHandler}>
                    <div className="accordion-body">
                        <div className="row g-3">
                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2">
                                        <h5 className="card-title">
                                            <b>PERSONAL INFORMATION</b>
                                        </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="username" className="form-label" >Full Name</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bx-user"></i></span>
                                                        <input className="form-control" type="text" id="Name" placeholder="Full Name"
                                                            name="Name" maxLength={100}
                                                            value={formData.Name} onChange={onChangeHandler} />
                                                    </div>
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.Name}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Number" className="form-label">Mobile Number</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bxs-phone"></i></span>

                                                        <input
                                                            className="form-control"
                                                            name="MobileNumber"
                                                            placeholder="xxxx xxx xxxx"
                                                            onChange={onChangeHandler}
                                                            value={formData.MobileNumber}
                                                            maxLength={10}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.MobileNumber}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Anumber" className="form-label">Alternative Mobile No</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bxs-phone"></i></span>

                                                        <Cleave type="tel" className="form-control" name="AlternateMobileNumber" placeholder="xxxx xxx xxxx"
                                                            maxLength={15}
                                                            onChange={onChangeHandler} value={formData.AlternateMobileNumber}
                                                            options={{ blocks: [4, 3, 3], delimiter: ' ', phone: true, phoneRegionCode: 'IN' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="email" className="form-label">Email</label>
                                                    <div className="input-group">
                                                        <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                                                        <input className="form-control" name="Email" placeholder="example@gmail.com" onChange={onChangeHandler} value={formData.Email} />

                                                    </div>
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.Email}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group text-start d-flex flex-column justify-content-start">
                                                    <div>
                                                        <label htmlFor="DateofBirth" className="form-label">Date of Birth</label>
                                                        <span className="required" style={{ color: "red" }}> *</span>
                                                    </div>                                                    
                                                    <Flatpickr
                                                        className="form-control"
                                                        id="DateofBirth"
                                                        name="DateofBirth"
                                                        value={formData.DateofBirth}
                                                        onChange={([date]) => onChangeHandler({ target: { name: "DateofBirth", value: date.toLocaleDateString("en-CA") } })}
                                                        options={{ dateFormat: "Y-m-d", static: true }}
                                                    />
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.DateofBirth}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Age" className="form-label">Age</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="Age"
                                                        name="Age"
                                                        placeholder="Enter Age"
                                                        value={formData.Age}
                                                        onChange={onChangeHandler}
                                                    />
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.Age}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-6" style={{ textAlign: 'left', display: 'block' }}>
                                                <label className="form-label">Gender</label>
                                                <span className="required" style={{ color: "red" }}> *</span>
                                                <div className="row">
                                                    <div className="col-md mb-md-0 mb-2">
                                                        <div className="form-check custom-option custom-option-basic">
                                                            <label className="form-check-label custom-option-content" htmlFor="customRadioTemp1">
                                                                <input name="Gender" className="form-check-input" type="radio" value="Male" id="customRadioTemp1" checked={formData.Gender === "Male"} onChange={onChangeHandler} />
                                                                <span className="custom-option-header">
                                                                    <span className="h6 mb-0">Male</span>
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-md">
                                                        <div className="form-check custom-option custom-option-basic">
                                                            <label className="form-check-label custom-option-content" htmlFor="customRadioTemp2">
                                                                <input name="Gender" className="form-check-input" type="radio" value="Female" id="customRadioTemp2" checked={formData.Gender === "Female"} onChange={onChangeHandler} />
                                                                <span className="custom-option-header">
                                                                    <span className="h6 mb-0">Female</span>
                                                                </span>

                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span className='non-valid' style={{ color: 'red', marginLeft: '20px' }}>{formError.Gender}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="event" className="form-label">Member Type</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <select
                                                        id="select2Success"
                                                        name="MemberTypeId"
                                                        className="form-select"
                                                        value={formData.MemberTypeId}
                                                        onChange={onChangeHandler}
                                                    >
                                                        <option value="">--Select MemberType--</option>
                                                        {membertype && membertype.map((option, index) => (
                                                            <option key={'event' + index} value={option.MemberTypeId}>
                                                                {option.Type}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.MemberTypeId}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2">
                                        <h5 className="card-title"><b>RM DETAILS</b> </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="row mx-2 mb-2">
                                            {/*<div className="col-md-6" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="routename" className="form-label">Route Name</label>
                                                <select
                                                    id="select2Success"
                                                    name="RouteMapId"
                                                    className="form-select"
                                                    value={formData.RouteMapId}
                                                    onChange={onChangeHandler}
                                                >
                                                    <option value="">--Select RouteName--</option>
                                                    {routename && routename.map((option, index) => (
                                                        <option key={'routename' + index} value={option.RouteMapId}>
                                                            {option.RouteName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div> */}
                                            <div className="col-md-6" style={{ textAlign: 'left', display: 'block' }}>
                                                <label htmlFor="rmName" className="form-label">RM Name</label>
                                                <select
                                                    id="select2Success"
                                                    name="RMId"
                                                    className="form-select"
                                                    value={formData.RMId}
                                                    onChange={onChangeHandler}
                                                >
                                                    <option value="">--Select RM Name--</option>
                                                    {rmName && rmName.map((option, index) => (
                                                        <option key={'rmName' + index} value={option.UserId}>
                                                            {option.UserName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-3">
                                <div className="card mt-3">
                                    <div className="card-header mx-2">
                                        <h5 className="card-title"><b>ADDRESS</b> <span className="required" style={{ color: "red" }}> *</span></h5>
                                    </div>
                                    <div id="accordionStyle3-1" className="accordion-collapse collapse show" data-bs-parent="#accordionStyle3">
                                        <div className="row mx-2 mb-2">
                                            <div className="col-md-4" style={{ textAlign: 'left', display: 'block' }}>
                                                <fieldset className="form-group">
                                                    {/* <label htmlFor="username" className="form-label">Address-1</label>*/}
                                                    <legend className="form-label">Address-1<span className="required" style={{ color: "red" }}> *</span></legend>
                                                    <input className="form-control" type="text" id="exampleFormControlInput1" placeholder="Enter Address"
                                                        name="AddressLine1" maxLength={150}
                                                        value={formData.AddressLine1} onChange={onChangeHandler} />
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.AddressLine1}</span>
                                                </fieldset>
                                            </div>
                                            <div className="col-md-4 ">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="username" className="form-label">Address-2</label>

                                                    <input className="form-control" type="text" id="exampleFormControlInput1" placeholder="Enter Address"
                                                        name="AddressLine2" maxLength={150}
                                                        value={formData.AddressLine2} onChange={onChangeHandler} />

                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label className="form-label">State</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <select
                                                        className="form-select"
                                                        name="StateId"
                                                        value={formData.StateId}
                                                        onChange={onChangeHandler}
                                                    >
                                                        <option value="">Select State</option>
                                                        {states.map(state => (
                                                            <option key={state.StateId} value={state.StateId}>{state.StateName}</option>
                                                        ))}
                                                    </select>
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.StateId}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label className="form-label">Districts</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <select
                                                        className="form-select"
                                                        name="DistrictId"
                                                        value={formData.DistrictId}
                                                        onChange={onChangeHandler}
                                                        disabled={isDistrictDisabled}
                                                    >
                                                        <option value="">Select District</option>
                                                        {districts.map(district => (
                                                            <option key={district.DistrictId} value={district.DistrictId}>{district.DistrictName}</option>
                                                        ))}
                                                    </select>
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.DistrictId}</span>
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Pincode" className="form-label">City/Town</label>
                                                    <input className="form-control" type="text" id="exampleFormControlInput1" placeholder="Enter City/Town Name"
                                                        name="City" maxLength={50}
                                                        value={formData.City} onChange={onChangeHandler}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Pincode" className="form-label">Pincode</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>

                                                    <input className="form-control" type="text"
                                                        name="Pincode"
                                                        pattern="[0-9]{6}"
                                                        maxLength="6"
                                                        id="exampleFormControlInput1"
                                                        placeholder="eg:123456"
                                                        onChange={onChangeHandler} value={formData.Pincode}
                                                    />
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.Pincode}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Village" className="form-label">Village</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <input className="form-control" type="text" id="exampleFormControlInput1" placeholder="Enter Village Name"
                                                        name="Village" maxLength={50}
                                                        value={formData.Village} onChange={onChangeHandler}
                                                    />
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.Village}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group" style={{ textAlign: 'left', display: 'block' }}>
                                                    <label htmlFor="Mandal" className="form-label">Mandal</label>
                                                    <span className="required" style={{ color: "red" }}> *</span>
                                                    <input className="form-control" type="text" id="exampleFormControlInput1" placeholder="Enter Mandal Name"
                                                        name="Mandal" maxLength={50}
                                                        value={formData.Mandal} onChange={onChangeHandler}
                                                    />
                                                    <span className='non-valid' style={{ color: 'red' }}>{formError.Mandal}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group" style={{ marginBottom: "50px" }}>
                                <div className="col-md-12 col-md-offset-2 d-flex align-items-end">
                                    <button className="btn btn-md btn-primary" type="submit" style={{ marginLeft: "10px", backgroundColor: "#5cb85c", borderColor: "#5cb85c" }}>
                                        Submit
                                    </button>
                                    <button className="btn btn-primary  ms-auto me-2" type="reset" onClick={handleBackToView}>
                                        Cancel
                                    </button>
                                    <button className="btn btn-md btn-danger" type="reset" onClick={resetForm}>
                                        Reset
                                    </button>
                                </div>
                            </div>

                            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                                <Alert onClose={handleSnackbarClose} severity="success">
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>
                        </div>
                    </div>
                </form>
            </div>
        </>

    );

}

