import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CommonTables from "../../Commoncomponents/CommonTables";
import { fetchData, fetchAllData } from "../../helpers/externapi";
import { downloadCSVData, downloadExcelData } from '../../Commoncomponents/CommonComponents';
import * as XLSX from "xlsx";






export default function HealthCampMembers(props) {
    const [loading, setLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [membersData, setMembersData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [viewType, setViewType] = useState("AllCustomers"); // Default view
    const [filterCriteria, setFilterCriteria] = useState([]);

    const { Id } = useParams();

    const tableHeads = ["FULL NAME", "CONTACT", "ADDRESS", "EVENT NAME", "Sold By", "EVENT DATE"];

    const tableElements = membersData.length > 0
        ? membersData.map((data) => [
            <Link
                to={`/customers/details/${data.MemberId}`}
                className="text-start-important"
                style={{
                    whiteSpace: "normal",
                    textAlign: "start",
                    display: "block",
                }}
            >
                {data.Name}
            </Link>,
            data.MobileNumber || data.Email ? (
                <>
                    <div>
                        {data.MobileNumber ? (
                            <a href={"tel:" + data.MobileNumber}>
                                <i className="bx bx-phone-call"></i>
                                {data.MobileNumber}
                            </a>
                        ) : "Mobile Number doesn't exist"}
                    </div>
                    <div>
                        {data.Email ? (
                            <a href={"mailto:" + data.Email} className="d-flex align-items-center" style={{ whiteSpace: "normal", wordBreak: "break-all" }}>
                                <i className="fas fa-envelope"></i>
                                {data.Email}
                            </a>
                        ) : "Email doesn't exist"}
                    </div>
                </>
            ) : "Mobile Number and Email Id don't exist",
            `${data.AddressLine1}, ${data.Village}, ${data.City}`,
            data.EventName,
            data.SaleDoneBy,
            data.EventDate ? moment(data.EventDate).format("DD-MMM-YYYY") : "",
        ])
        : [];



    const handleAllCustomersClick = async () => {

        setLoading(true);
        setViewType("AllCustomers");

        try {
            const distributorCountData = await fetchAllData(`MemberEvents/MemberEventDetailsCountById/${Id}`);
            const totalCount = distributorCountData.count || 0;
            setTotalCount(totalCount);

        } catch (error) {
            console.error("Error fetching hospital count data:", error);

        }

        try {
            const data = await fetchData("MemberEvents/MemberEventDetailsById", {
                skip: (currentPage - 1) * perPage,
                take: perPage,
                eventid: Id,
            });

            setMembersData(data)
        } catch (error) {
            console.error("Error fetching all customers:", error);
            setSnackbarMessage("Failed to fetch all customers. Please try again.");
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleNonCustomersClick = async () => {

        setLoading(true);
        setViewType("NonCustomers");

        try {
            const distributorCountData = await fetchData("MemberEvents/GetCustomersAndLeadsDataCount", {
                eventid: Id,
                IsCustomers: false,
            });
            const totalCount = distributorCountData[0].totalCount || 0;
            setTotalCount(totalCount);

        } catch (error) {
            console.error("Error fetching hospital count data:", error);
        }



        try {
            const data = await fetchData("MemberEvents/GetCustomersAndLeadsData", {
                skip: (currentPage - 1) * perPage,
                take: perPage,
                eventid: Id,
                IsCustomers: false,
            });

            setMembersData(data);
        } catch (error) {
            console.error("Error fetching non-customers:", error);
            setSnackbarMessage("Failed to fetch non-customers. Please try again.");
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handlePurchasedCustomersClick = async () => {

        setLoading(true);
        setViewType("PurchasedCustomers");
        try {
            const distributorCountData = await fetchData("MemberEvents/GetCustomersAndLeadsDataCount", {
                eventid: Id,
                IsCustomers: true,
            });
            const totalCount = distributorCountData[0].totalCount || 0;
            setTotalCount(totalCount);

        } catch (error) {
            console.error("Error fetching hospital count data:", error);
        }


        try {
            const data = await fetchData("MemberEvents/GetCustomersAndLeadsData", {
                skip: (currentPage - 1) * perPage,
                take: perPage,
                eventid: Id,
                IsCustomers: true,
            });
            setMembersData(data);
        } catch (error) {
            console.error("Error fetching purchased customers:", error);
            setSnackbarMessage("Failed to fetch purchased customers. Please try again.");
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (viewType === "AllCustomers") {
            handleAllCustomersClick();
        } else if (viewType === "NonCustomers") {
            handleNonCustomersClick();
        } else {
            handlePurchasedCustomersClick();
        }
    }, [viewType, currentPage, perPage]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handlePerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const generateExcelFile = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        return XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    };

    const generateCSVFile = (data) => {
        const headers = Object.keys(data[0]).join(",") + "\n";
        const rows = data.map((row) => Object.values(row).join(",")).join("\n");
        return headers + rows;
    };



    const downloadExcelOrCSV = async (type) => {
        setLoading(true);
        try {
            // Determine API endpoint and parameters
            const endpoint =
                viewType === "AllCustomers"
                    ? "MemberEvents/MemberEventDetailsById"
                    : "MemberEvents/GetCustomersAndLeadsData";

            const params =
                viewType === "AllCustomers"
                    ? {
                        skip: 0, // Optional: Adjust pagination as needed
                        take: totalCount, // Fetch all data
                        eventid: Id,
                    }
                    : {
                        skip: 0,
                        take: totalCount,
                        eventid: Id,
                        IsCustomers: viewType === "PurchasedCustomers",
                    };

            // Fetch data
            const data = await fetchData(endpoint, params);
            if (!data || data.length === 0) {
                throw new Error("No data available for download.");
            }

            // Generate file content
            const fileContent = type === "excel" ? generateExcelFile(data) : generateCSVFile(data);

            // Trigger download
            const blob = new Blob([fileContent], {
                type: type === "excel" ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : "text/csv",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `healthcamp_${viewType}_${type === "excel" ? "data.xlsx" : "data.csv"}`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error(`Error downloading ${type.toUpperCase()} file:`, error);
            setSnackbarMessage(`Failed to download ${type.toUpperCase()} file. Please try again.`);
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    // Excel and CSV Download Handlers
    const handleExcelDownload = () => downloadExcelOrCSV("excel");
    const handleCSVDownload = () => downloadExcelOrCSV("csv");




    const skeletonloading = () => (
        <>
            <style>{shimmerStyle}</style>
            <div className="shimmer-container shimmer">
                <h6 className="shimmer-text "></h6>
            </div>
            <table className="shimmer-container shimmer">
                <tbody>
                    {Array.from({ length: 6 }).map((_, rowIndex) => (
                        <tr key={rowIndex} className="shimmer-row">
                            {Array.from({ length: 6 }).map((_, colIndex) => (
                                <td key={colIndex} className="shimmer-cell">
                                    <h6 className="shimmer-text2 " ></h6>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>

        </>
    )

    return (
        <>
            {loading && skeletonloading()}
            {!loading && (

                <>
                    <div className="row mt-2">
                        <div className="col-xl-12 col-lg-12 col-md-12 order-0 order-md-1">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div
                                            className="col-md-12 d-flex justify-content-between align-items-center flex-wrap gap-2"
                                            style={{ gap: "10px" }}
                                        >
                                            <div className="d-flex gap-2">
                                                <button
                                                    className={`btn btn-sm ${viewType === "AllCustomers" ? "btn-primary" : "btn-secondary"
                                                        }`}
                                                    style={{ padding: "5px 10px" }}
                                                    onClick={handleAllCustomersClick}
                                                >
                                                    All
                                                </button>
                                                <button
                                                    className={`btn btn-sm ${viewType === "PurchasedCustomers" ? "btn-primary" : "btn-secondary"
                                                        }`}
                                                    style={{ padding: "5px 10px" }}
                                                    onClick={handlePurchasedCustomersClick}
                                                >
                                                    Customers
                                                </button>
                                                <button
                                                    className={`btn btn-sm ${viewType === "NonCustomers" ? "btn-primary" : "btn-secondary"
                                                        }`}
                                                    style={{ padding: "5px 10px" }}
                                                    onClick={handleNonCustomersClick}
                                                >
                                                    Non-Customers
                                                </button>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <button
                                                    className="btn btn-secondary btn-sm btn-primary"
                                                    onClick={handleExcelDownload}
                                                >
                                                    <span>
                                                        <i className="bx bx-export me-sm-1"></i>
                                                        <span className="d-none d-sm-inline-block"> Excel</span>
                                                    </span>
                                                </button>

                                                <button
                                                    className="btn btn-secondary btn-sm btn-success"
                                                    onClick={handleCSVDownload}
                                                >
                                                    <span>
                                                        <i className="bx bx-export me-sm-1"></i>
                                                        <span className="d-none d-sm-inline-block"> CSV</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {(loading || tableLoading) && (
                                        <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.7)", display: "flex", justifyContent: "center", alignItems: "center", zIndex: 1000 }}>
                                            <CircularProgress />
                                        </div>
                                    )}

                                    {!loading && !tableLoading && membersData.length === 0 && (
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            There are no records to display.
                                        </div>
                                    )}

                                    {!tableLoading && membersData.length > 0 && (
                                        <CommonTables
                                            tableHeads={tableHeads}
                                            tableData={tableElements}
                                            perPage={perPage}
                                            currentPage={currentPage}
                                            perPageChange={handlePerPageChange}
                                            pageChange={handlePageChange}
                                            totalCount={totalCount}
                                        />
                                    )}

                                    <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                                        <Alert onClose={handleSnackbarClose} severity="error">
                                            {snackbarMessage}
                                        </Alert>
                                    </Snackbar>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

const shimmerStyle = `
     @keyframes shimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  .shimmer {
    animation-duration: 1.0s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background:	#F7F7F7;
    background: linear-gradient(to right, #f0f0f0 8%, #fafafa 18%, #f0f0f0 33%);
    background-size: 1000px 104px;
    position: relative;
    overflow: hidden;
  }

  .shimmer-container {
    background-color: 	#F7F7F7;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    margin: 15px;
  }

  .shimmer-text2 {
    background-color: #C8C8C8;
    border-radius: 4px;
    height: 15px;
    width: 55%;
    margin: 15px 0 0 15px;
     position:relative;
     left:10%;
     bottom:10%;
  }
  .shimmer-text {
    background-color: #C8C8C8;
    border-radius: 4px;
    height: 15px;
    width: 15%;
    margin: 15px 0 0 15px;
     
  }
 .shimmer-row {
    display: flex;
  }

  .shimmer-cell {
    flex: 1;
    padding: 10px;
    height: 50px;
    background-color: #F7F7F7;
    border-radius: 4px;
    margin: 5px;
  }
  
  `;

const styles = {
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        padding: '12px 15px',
        border: '1px solid #ddd',
        textAlign: 'left',
        backgroundColor: '#f2f2f2',
        color: '#333',
        textTransform: 'uppercase',
        fontSize: '12px',
        letterSpacing: '1px',
    },
    td: {
        padding: '12px 15px',
        border: '1px solid #ddd',
        textAlign: 'left',
        fontSize: '14px',
        whiteSpace: 'normal',
        maxWidth: '200px',
    },
    headerRow: {
        backgroundColor: '#f9f9f9',
    },
    paginationContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
    },
    paginationSelect: {
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid',
        marginRight: '10px',
        borderColor: 'blue',
    },
};