import { createSlice } from '@reduxjs/toolkit';

const menus = '';

const MenusSlice = createSlice({
  name: 'MenusSlice',
  initialState: menus,
  reducers: {
    setMenus: (state, action) => action.payload
  },
});

// Export actions with clear names to avoid conflicts
export const { setMenus } = MenusSlice.actions;

// Export reducers with clear names to avoid multiple default exports
export const MenusReducer = MenusSlice.reducer;

