import React, { useEffect, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { useLocation } from 'react-router-dom';
import { fetchAllData, fetchData } from '../../helpers/externapi';

const BookConsultation = () => {
    const [mobileNumber, setMobileNumber] = useState("");
    const [isVerified, setIsVerified] = useState(false);
    const [input, setInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        FullName: '', MobileNumber: '', Cardnumber: '', Gender: '', DateofBirth: '', Age: '', Address: '',
        DateAndTime: '', DoctorName: '', ServiceTypeId: '', Appointment: 'Free Consultation',
        HospitalId: '', HospitalName: '',
    });
    const [formErrors, setFormErrors] = useState({ DateAndTime: '', HospitalId: '', ServiceTypeId: '', Appointment: '' });
    const [eligibilityMessage, setEligibilityMessage] = useState();
    const [formSuccessMessage, setFormSuccessMessage] = useState();
    const [memberDetails, setMemberDetails] = useState();
    const [dependents, setDependents] = useState();
    const [verifiedMessage, setVerifiedMessage] = useState();
    const [serviceTypes, setServiceTypes] = useState([]);
    const [formLoading, setFormLoading] = useState(false);
    const [verifyLoading, setVerifyLoading] = useState(false);

    const location = useLocation();
    const { memberId: initialMemberId } = location.state || '';
    const [memberId, setMemberId] = useState(initialMemberId);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    useEffect(() => {
        if (memberId) {
            setIsVerified(true);
        }
    }, [memberId]);

    useEffect(() => {
        const fetchMemberDetails = async () => {
            const responseMemberDetails = await fetchAllData(`OHOCards/GetMemberDetailsId/${memberId}`);
            setMemberDetails(responseMemberDetails);

            responseMemberDetails && responseMemberDetails.length > 0 && (
                setFormData((preVal) => ({
                    ...preVal, FullName: responseMemberDetails[0].FullName, MobileNumber: responseMemberDetails[0].MobileNumber, Cardnumber: responseMemberDetails[0].OHOCardNumber,
                    Gender: responseMemberDetails[0].Gender, DateofBirth: formatDate(responseMemberDetails[0].DateofBirth), Age: calculateAge(responseMemberDetails[0].DateofBirth),
                    Address: responseMemberDetails[0].AddressLine1
                }))
            )
        };

        const fetchDependents = async () => {
            const responseDependents = await fetchAllData(`MemberDependent/GetByMemberId/${memberId}`);
            setDependents(responseDependents);
        };

        const fetchServiceTypes = async () => {
            const getServiceTypes = await fetchData("HospitalServices/all", { skip: 0, take: 0 });
            setServiceTypes(getServiceTypes);
        };

        if (memberId) {
            fetchMemberDetails();
            fetchDependents();
        }

        fetchServiceTypes();
    }, [memberId]);

    const handleInputChange = async (event) => {
        const value = event.target.value;
        setInput(value);

        // Always call the API, even for an empty input
        setSearchLoading(true);
        setError('');

        try {
            const filter = value
                ? [{ key: "HospitalName", value: value, operator: "LIKE" }]
                : [{ key: "", value: "", operator: "" }];

            const hospitalNameSuggestions = await fetchData(
                "Hospital/GetHospitalListByKeyValuePair",
                { filter }
            );

            if (hospitalNameSuggestions.length > 0) {
                setSuggestions(hospitalNameSuggestions);
            } else {
                setError('No suggestions found.');
                setSuggestions([]);
            }
        } catch (error) {
            setError('Failed to fetch suggestions.');
            setSuggestions([]);
        } finally {
            setSearchLoading(false);
        }
    };

    const clearSearch = () => {
        setInput('');
        setSuggestions([]);
    };

    const handleVerifyInputChange = (e) => {
        const value = e.target.value;

        if (/^\d*$/.test(value)) {
            setMobileNumber(value);
        }
    };

    const formatCardNumber = (number) => {
        return number.replace(/\s+/g, '').replace(/(.{4})(?=.{4})/g, '$1 ');
    };

    const handleVerify = async (e) => {
        e.preventDefault();

        const isCardNumber = mobileNumber.length === 12;
        const formattedCardNumber = isCardNumber ? formatCardNumber(mobileNumber) : mobileNumber;

        const payload = {
            mobileNumber: isCardNumber ? "" : mobileNumber,
            cardNumber: isCardNumber ? formattedCardNumber : ""
        };

        try {
            setVerifyLoading(true);
            const response = await fetchData(`OHOCards/GetMemberDetailsByCardNumberuorMobileNo`, payload);

            if (response.status === true) {
                setMemberId(response.memberId);
                setVerifiedMessage(response.message)
                setIsVerified(true);
            } else {
                setVerifiedMessage(response.message)
            }
        } catch (error) {
            console.error('Error during verification:', error);
            alert('An error occurred while verifying. Please try again.');
        } finally {
            setVerifyLoading(false);
        }
    };

    function calculateAge(dateOfBirth) {
        const dob = new Date(dateOfBirth);
        const today = new Date();

        let age = today.getFullYear() - dob.getFullYear();
        const monthDifference = today.getMonth() - dob.getMonth();

        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
            age--;
        }

        return age;
    }

    const formatDate = (dobString) => {
        const date = new Date(dobString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };

        return date.toLocaleDateString('en-US', options);
    };

    const formateDatabaseDatetime = (inp) => {
        const date = new Date(inp);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    const onChangeDateTime = (e) => {
        const dateStr = e.toString();
        const date = formateDatabaseDatetime(dateStr.slice(0, 24));

        if (date.length > 0) {
            setFormErrors(preVal => ({
                ...preVal, DateAndTime: ''
            }))
        }

        setFormData(preVal => ({
            ...preVal, DateAndTime: date
        }))
    };

    const onChangeHandler = (e) => {

        if (e.target.name === 'FullName') {
            memberDetails[0].FullName === e.target.value ? (
                setFormData((preVal) => ({
                    ...preVal, FullName: memberDetails[0].FullName, MobileNumber: memberDetails[0].MobileNumber, Cardnumber: memberDetails[0].OHOCardNumber,
                    Gender: memberDetails[0].Gender, DateofBirth: (memberDetails[0].DateofBirth), Age: calculateAge(memberDetails[0].DateofBirth),
                    Address: memberDetails[0].AddressLine1
                }))
            ) : dependents.map(each => (
                each.fullName === e.target.value && (
                    setFormData((preVal) => ({
                        ...preVal, FullName: each.fullName, Gender: each.gender, DateofBirth: (each.dateofBirth), Age: calculateAge(each.dateofBirth),
                    }))
                )
            ))
            setFormData(preVal => ({
                ...preVal, [e.target.name]: e.target.value
            }))

        } else if (e.target.name === 'ServiceTypeId') {
            setFormData(preVal => ({
                ...preVal, [e.target.name]: parseInt(e.target.value)
            }))
            if (e.target.value.length > 0) {
                setFormErrors(preVal => ({
                    ...preVal, [e.target.name]: ''
                }))
            }
        } else {
            setFormData(preVal => ({
                ...preVal, [e.target.name]: e.target.value
            }))
            if (e.target.value.length > 0) {
                setFormErrors(preVal => ({
                    ...preVal, [e.target.name]: ''
                }))
            }
        }
    };

    const checkErrors = () => {
        if (formData.DateAndTime === '' || formData.HospitalId === '' ||
            formData.ServiceTypeId.length < 2) {

            if (formData.DateAndTime === '') {
                setFormErrors(preVal => ({
                    ...preVal, DateAndTime: 'Please select appointment date & time *'
                }))
            }
            if (formData.HospitalId === '') {
                setFormErrors(preVal => ({
                    ...preVal, HospitalId: 'Please Enter valid hospital name *'
                }))
            }
            if (formData.ServiceTypeId.length < 2) {
                setFormErrors(preVal => ({
                    ...preVal, ServiceTypeId: 'Please Enter servicetype *'
                }))
            }

        } else {
            return true;
        }
    };

    const handleSuggestionClick = (hospital) => {
        setInput(hospital.HospitalName);
        setFormData({
            ...formData,
            HospitalId: hospital.HospitalId,
            HospitalName: hospital.HospitalName
        });
        setSuggestions([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const noError = checkErrors();
        if (noError) {
            const payload = {
                name: formData.FullName,
                mobileNumber: formData.MobileNumber,
                cardNumber: formData.Cardnumber,
                gender: formData.Gender,
                dateofBirth: formateDatabaseDatetime(formData.DateofBirth),
                age: formData.Age,
                appointmentDate: formData.DateAndTime,
                address: formData.Address,
                hospitalId: formData.HospitalId,
                hospitalName: formData.HospitalName,
                serviceTypeId: formData.ServiceTypeId,
                consultationFee: formData.ConsultationFee,
                memberId: memberId,
                doctorName: formData.DoctorName,
                appointment: formData.Appointment
            };

            setFormLoading(true);

            const responseEligible = await fetchData(`BookingConsultation/bookAppointment/add`, { ...payload });

            if (responseEligible.status) {
                setFormSuccessMessage(responseEligible.message);
                setEligibilityMessage('');

                setFormErrors({
                    DateAndTime: '', HospitalId: '', ServiceTypeId: '', Appointment: ''
                });

                setTimeout(() => {
                    setFormData(preVal => ({
                        ...preVal, DateAndTime: '', HospitalId: '', DoctorName: '', ServiceTypeId: '', Appointment: '',
                        DiscountPercentage: '', ConsultationFee: ''
                    }));

                    setInput('');
                    setEligibilityMessage('');
                    setFormSuccessMessage('');
                    //navigate('/Others/BookConsultation/upcoming');
                    //window.location.reload();
                }, 3000);
            } else {
                setEligibilityMessage(responseEligible.message);
                setFormSuccessMessage('');
            }

            setFormLoading(false);

        } else {
            setEligibilityMessage('');
            setFormSuccessMessage('');
        }
    };

    const handleReset = (e) => {
        e.preventDefault();

        setFormData(preVal => ({
            ...preVal, DateAndTime: '', HospitalId: '', DoctorName: '', ServiceTypeId: '', Appointment: '',
            DiscountPercentage: 0.0, ConsultationFee: 0
        }));
        setInput('');

        setFormErrors({
            DateAndTime: '', HospitalId: '', Branch: '', ServiceTypeId: '', Appointment: ''
        })
    };

    const handleCancel = () => {
        setIsVerified(false);
    };

    const returnForm = () => (
        <div className='card p-3'>
            <form>
                <div className="row">

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label htmlFor="FullName" className="form-select-label">
                            Name<span className="text-danger"> *</span>
                        </label>
                        <select
                            name="FullName"
                            id="FullName"
                            className="form-select"
                            value={formData.FullName}
                            onChange={(e) => onChangeHandler(e)}
                        >
                            {memberDetails && memberDetails.length > 0 && (
                                <option value={memberDetails[0].FullName}>{memberDetails[0].FullName}</option>
                            )}

                            {dependents && dependents.length > 0 && dependents.map((dependent, index) => (
                                <option key={index} value={dependent.fullName}>{dependent.fullName}</option>
                            ))}
                        </select>
                    </div>

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label htmlFor="flatpickr-datetime" className="form-control-label">
                            Consultation Date &amp; Time<span className="text-danger"> *</span>
                        </label>
                        <Flatpickr
                            className="form-control"
                            placeholder="YYYY-MM-DD HH:MM"
                            id="flatpickr-datetime"
                            name="DateAndTime"
                            value={formData.DateAndTime}
                            onChange={(e) => onChangeDateTime(e)}
                            options={{
                                enableTime: true,
                                dateFormat: "Y-m-d H:i",
                                time_24hr: false,
                                minDate: today
                            }}
                        />
                        {formErrors && formErrors.DateAndTime.length > 0 && <p className='text-danger'>{formErrors.DateAndTime}</p>}
                    </div>

                    <div className="col-8 col-md-4">
                        <div>
                            <label htmlFor="search-input" className="form-label">Hospital Name <span className="text-danger"> *</span></label>
                            <div style={{ position: 'relative', maxWidth: '350px' }}>
                                <input
                                    type="text"
                                    id="search-input"
                                    className="form-control"
                                    placeholder="Enter Hospital Name"
                                    style={{ paddingLeft: '30px' }}
                                    maxLength="50"
                                    value={input}
                                    onChange={handleInputChange}
                                />
                                {input && (
                                    <i
                                        className="fas fa-times-circle"
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            fontSize: '16px',
                                            color: 'red',
                                            cursor: 'pointer'
                                        }}
                                        onClick={clearSearch}
                                    ></i>
                                )}
                                {searchLoading && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            right: '40px',
                                            top: '50%',
                                            transform: 'translateY(-50%)'
                                        }}
                                    >
                                        <div className="spinner-border text-primary" role="status" style={{ width: '1.5rem', height: '1.5rem' }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                {formErrors && formErrors.HospitalId.length > 0 && <p className='text-danger'>{formErrors.HospitalId}</p>}

                                {input && !searchLoading && suggestions.length > 0 && (
                                    <ul
                                        style={{
                                            listStyleType: 'none',
                                            padding: '0',
                                            margin: '0',
                                            border: '1px solid #00796b',
                                            borderRadius: '4px',
                                            maxHeight: '200px',
                                            overflowY: 'auto',
                                            position: 'absolute',
                                            width: '100%',
                                            backgroundColor: '#fff',
                                            zIndex: 10,
                                            top: '100%',
                                            left: '0'
                                        }}
                                    >
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                onClick={() => handleSuggestionClick(suggestion)}
                                                style={{
                                                    padding: '8px',
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    position: 'relative'
                                                }}
                                            >
                                                <i
                                                    className="fas fa-arrow-up-left"
                                                    style={{
                                                        marginRight: '8px',
                                                        color: '#00796b'
                                                    }}
                                                ></i>
                                                <span style={{ flex: 1 }}>{suggestion.HospitalName}</span>
                                            </li>
                                        ))}
                                    </ul>
                                )}

                                {input && !searchLoading && error && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: '100%',
                                            left: '0',
                                            color: 'red',
                                            fontSize: '0.9rem',
                                            marginTop: '4px',
                                            backgroundColor: '#fff', // Matches suggestion background
                                            border: '1px solid #00796b', // Matches suggestion border
                                            borderRadius: '4px', // Matches suggestion border-radius
                                            padding: '8px', // Adds spacing similar to suggestions
                                            zIndex: 10, // Ensures it appears above other elements
                                            width: '100%' // Ensures it aligns with the input width
                                        }}
                                    >
                                        {error}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label className="form-control-label">Doctor Name</label>
                        <input type="text" name="DoctorName" className="form-control" placeholder="Enter Doctor Name"
                            value={formData.DoctorName}
                            onChange={(e) => onChangeHandler(e)}
                        />
                    </div>

                    <div className="d-flex flex-column col-12 col-md-4 mb-3">
                        <label className="form-control-label">
                            Servive Type<span className="text-danger"> *</span>
                        </label>
                        <select name="ServiceTypeId" className="form-select" placeholder="Ex: Orthopedic"
                            value={formData.ServiceTypeId} onChange={(e) => onChangeHandler(e)}>
                            <option>--- SELECT ---</option>
                            {serviceTypes && serviceTypes.length > 0 && serviceTypes.map(type => (
                                <option key={type.HospitalServicesId} value={type.HospitalServicesId}>{type.ServiceName}</option>
                            ))}
                        </select>
                        {formErrors && formErrors.ServiceTypeId.length > 0 && <p className='text-danger'>{formErrors.ServiceTypeId}</p>}
                    </div>

                </div>
                <div className="text-center">
                    <button type="button" className="btn btn-secondary me-1" onClick={(e) => handleCancel(e)}>Cancel</button>
                    <button type="button" className="btn btn-danger me-1" onClick={(e) => handleReset(e)}>Reset</button>
                    <button type="submit" className="btn btn-success" onClick={(e) => handleSubmit(e)}
                        disabled={formLoading} style={{ minWidth: '100px', minHeight: '40px' }}
                    >
                        {formLoading ? (
                            <div className="spinner-border text-white" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        ) : 'Submit'}
                    </button>
                </div>
                {formSuccessMessage && formSuccessMessage.length > 0 && <p className='text-success text-center'>{formSuccessMessage}</p>}
                {eligibilityMessage && eligibilityMessage.length > 0 && <p className='text-danger text-center'>{eligibilityMessage}</p>}
            </form>
        </div>
    );

    return (
        isVerified ? (returnForm()) : (
            <div className='card p-2'>
                <div className='col-12 col-md-6'>
                    <label htmlFor="mobileNumber" className="form-label">
                        Mobile Number/OHOCard Number<span className="text-danger"> *</span>
                    </label>

                    <div className='d-flex flex-row align-items-center'>
                        <input
                            type="text"
                            className="form-control p-0 me-3 mb-3"
                            maxLength="12"
                            id="mobileNumber"
                            value={mobileNumber}
                            onChange={handleVerifyInputChange}
                            style={{ minHeight: '40px' }}
                        />
                        <button
                            type="button"
                            className="btn btn-primary mb-3"
                            onClick={handleVerify}
                            disabled={mobileNumber.length < 10 || verifyLoading}
                            style={{ minWidth: '100px', minHeight: '40px' }}
                        >
                            {verifyLoading ? (
                                <div className="spinner-border text-white" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : "Verify"}                            
                        </button>
                    </div>
                </div>
                {verifiedMessage && verifiedMessage.length > 0 && <p className='text-danger text-center'>{verifiedMessage}</p>}
            </div>
        )
    )
};

export default BookConsultation;